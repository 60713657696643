import { Badge, Button, Calendar, Layout, Modal, notification, Spin, Tag, Typography } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import TespitEkle from "./TespitEkle";
import { useTespitleriGetir } from "../hooks/useTespit";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
const { Header, Content } = Layout;

function TespitList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const { tespitler } = useTespitleriGetir();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setDataSource(tespitler);
        setFilteredSource(tespitler);
        setIsPending(false);
    }, [tespitler]);

    const dateCellRender = (value) => {
        return dataSource.map((item, index) => {
            return value.format("YYYY-MM-DD") == dayjs(item.baslangic).format("YYYY-MM-DD") ? (
                <Badge
                    size="large"
                    status="error"
                    onClick={() => {
                        navigate(`/tespitGuncelle/${item.tespitID}`);
                    }}
                    text={item.firmaismi}
                />
            ) : null;
        });
    };

    const ekleButon = (
        <Button
            key="ekleButon"
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger={errMessage}
            size="small"
            onClick={() => {
                setModalOpen(true);
            }}
        >
            {t("genel.ekle")}
        </Button>
    );

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="sayfaBaslik.tespitList" buttons={[ekleButon]} backURL="/" />
                {contextHolder}
                <Calendar
                    cellRender={dateCellRender}
                    fullscreen={true}
                    mode="month"
                    style={{ marginLeft: 10, marginRight: 10, marginBottom: 10, padding: 10 }}
                />
                <Modal
                    key="modalForm"
                    open={modalOpen}
                    onOk={() => {
                        setModalOpen(false);
                    }}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="tespitEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <TespitEkle />
                </Modal>
            </Content>
        </Layout>
    );
}

export default TespitList;
