import { Button, Image, Layout, Modal, Dropdown, Space, Tooltip } from "antd";
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, MenuOutlined } from "@ant-design/icons";
import BildirimEkle from "./BildirimEkle";
import { auth } from "../firebase/config";

const { Header, Content } = Layout;

function KmyHeader(props) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    //prettier-ignore
    const items = [
        {
            key: "menu",
            label:t("genel.dilSecimi"),
            children: [
                { label: ( <a onClick={() => { i18n.changeLanguage("tr"); }} >Türkçe</a> ), key: "tr", },
                { label: ( <a onClick={() => { i18n.changeLanguage("en"); }} >English</a>), key: "en", },
                { label: ( <a onClick={() => { i18n.changeLanguage("de"); }} >German</a> ), key: "de", }
            ]
        },
        { label: ( <a onClick={() => { setModalOpen(true); }} >Hata / istek bildir</a> ), key:"kmyHeaderTicketButton" },
        { label: ( <a onClick={() => { auth.signOut(); }} >{t("login.logout")}</a> ), key:"logout" },
     ];

    return (
        <Header
            style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#555",
                color: "#FFF",
                fontSize: 18,
                marginBottom: 20,
            }}
        >
            <Space
                style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    alignItems: "center",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Space>
                    <Tooltip title={t("genel.homepage")}>
                        <Image
                            src="http://pro.kmysistem.com/images/kmyLogoK.png"
                            style={{ height: 58, width: 40, marginLeft: -45, cursor: "pointer" }}
                            preview={false}
                            onClick={() => {
                                navigate("/");
                            }}
                        />
                    </Tooltip>
                    {props.backURL && (
                        <Tooltip title={t("genel.geri")}>
                            <Button
                                icon={<ArrowLeftOutlined />}
                                type="text"
                                disabled={false}
                                style={{ color: "white" }}
                                onClick={() => {
                                    navigate(props.backURL);
                                }}
                            />
                        </Tooltip>
                    )}
                    {t(`${props.caller}`)}
                </Space>
                <Space>
                    {props.buttons}
                    <Content>
                        <Dropdown menu={{ items }}>
                            <Button type="link" style={{ color: "#FFF", marginRight: -20 }} icon={<MenuOutlined />} />
                        </Dropdown>
                        <Modal
                            open={modalOpen}
                            onOk={() => {
                                setModalOpen(false);
                            }}
                            onCancel={() => {
                                setModalOpen(false);
                            }}
                            footer={[
                                <Button
                                    onClick={() => {
                                        setModalOpen(false);
                                    }}
                                    style={{ marginRight: 20 }}
                                >
                                    {t("genel.vazgec")}
                                </Button>,
                                <Button
                                    type="primary"
                                    form="bildirimEkleForm"
                                    htmlType="submit"
                                    onClick={() => {
                                        setModalOpen(false);
                                    }}
                                >
                                    {t("genel.kaydet")}
                                </Button>,
                            ]}
                        >
                            <BildirimEkle caller={props.caller} />
                        </Modal>
                    </Content>
                </Space>
            </Space>
        </Header>
    );
}

export default KmyHeader;
