import { useGetToken } from "./useGetToken";
import { useEffect, useState } from "react";
import { v4 } from "uuid";

export const useBildirimEkle = () => {
    const [bildirim, setbildirim] = useState();
    const { accessToken } = useGetToken();

    const bildirimEkle = async (e) => {
        console.log("eklenecek : ", e);
        fetch(`http://89.19.24.18:3001/Bildirim/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                bildirimID: e.bildirimID ? e.bildirimID : v4(),
                yer: e.yer ? e.yer : "",
                aciklama: e.aciklama ? e.aciklama : "",
                cozumOnerisi: e.cozumOnerisi ? e.cozumOnerisi : "",
                firmaID: e.firmaID ? e.firmaID : "FFFFFF",
                bildirenID: e.kisiID ? e.kisiID : "",
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data.body);
            });
    };

    return { bildirimEkle };
};

export const useBildirimGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const bildirimGuncelle = async ({ id, e }) => {
        console.log("bildirim id: ", e);
        e.bildirimID = id;
        fetch(`http://89.19.24.18:3001/Bildirim/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { bildirimGuncelle };
};

export const useBildirimSil = (id) => {
    const { accessToken } = useGetToken();
    const bildirimSil = async () => {
        fetch(`http://89.19.24.18:3001/Bildirim/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { bildirimSil };
};

export const useBildirimleriGetir = () => {
    const { accessToken } = useGetToken();
    const [bildirimler, setBildirimler] = useState([]);
    const bildirimleriGetir = async () => {
        fetch("http://89.19.24.18:3001/Bildirim", {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setBildirimler(data);
            });

        return () => bildirimler;
    };
    useEffect(() => {
        bildirimleriGetir();
    }, []);

    return { bildirimler };
};

export const useBildirimiGetir = (id) => {
    const [bildirim, setBildirim] = useState([]);
    const { accessToken } = useGetToken();
    const bildirimyiGetir = async () => {
        fetch(`http://89.19.24.18:3001/Bildirim/byId/${id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setBildirim(data);
            });

        return () => bildirim;
    };

    useEffect(() => {
        bildirimyiGetir();
    }, []);

    return { bildirim };
};

/*
export const useBildirenleriGetir = () => {
    const { accessToken } = useGetToken();
    const [bildirenler, setBildirenler] = useState([]);
    const bildirenleriGetir = async () => {
        fetch("http://89.19.24.18:3001/Bildirim/bildiren", {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setBildirenler(data);
            });

        return () => bildirenler;
    };
    useEffect(() => {
        bildirenleriGetir();
    }, []);

    return { bildirenler };
};
*/
