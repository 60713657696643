import { Form, Input, Layout, notification } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "antd-phone-input";
import { useKisiEkle } from "../hooks/useKisi";

const { Content } = Layout;

function KisiEkle() {
    const { t, i18n } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const { kisiEkle } = useKisiEkle();

    const onSubmit = (e) => {
        kisiEkle({
            isim: e.isim,
            soyad: e.soyad,
            email: e.email ? e.email : "",
            telefonNo: e.telefonNo ? e.telefonNo : "",
            TCno: e.TCno ? e.TCno : "",
            firmaID: "",
        });
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="kisiEkleForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    {contextHolder}
                    <Form.Item
                        name="isim"
                        label={t("kisi.isim")}
                        rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                        hasFeedback
                    >
                        <Input
                            placeholder={t("kisi.isimHint")}
                            maxLength={20}
                            onInput={(e) => {
                                e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="soyad"
                        label={t("kisi.soyad")}
                        rules={[
                            { required: true, message: t("genel.gerekli") },
                            { whitespace: true },
                            {
                                transform: (v) => {
                                    v = v.toUpperCase();
                                },
                            },
                        ]}
                        hasFeedback
                    >
                        <Input placeholder={t("kisi.soyadHint")} onInput={(e) => (e.target.value = e.target.value.toUpperCase())} maxLength={20} />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={t("kisi.email")}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (getFieldValue("loginType") !== "M") {
                                        return Promise.resolve();
                                    } else {
                                        return value.length === 0 ? Promise.reject(t("genel.gerekli")) : Promise.resolve();
                                    }
                                },
                                message: t("genel.gerekli"),
                            }),
                        ]}
                    >
                        <Input placeholder={t("kisi.emailHint")} maxLength={60} />
                    </Form.Item>
                    <Form.Item name="telefonNo" label={t("kisi.telefonNo")}>
                        <PhoneInput defaultCountry={"TR"} inputComponent={Input} placeholder={t("kisi.telefonNo")} />
                    </Form.Item>{" "}
                    <Form.Item
                        name="TCno"
                        label={t("kisi.TCno")}
                        rules={[
                            {
                                warningOnly: true,
                                validator(_, tc) {
                                    if (tc) {
                                        if (tc.length === 11 && tc[0] !== "0") {
                                            var tek = Number(tc[0]) + Number(tc[2]) + Number(tc[4]) + Number(tc[6]) + Number(tc[8]);
                                            var cift = Number(tc[1]) + Number(tc[3]) + Number(tc[5]) + Number(tc[7]);
                                            var t10 = (tek * 7 - cift) % 10;
                                            if (Number(tc[9]) === t10 && Number(tc[10]) === (cift + tek + t10) % 10) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(t("kisi.invalidTckn"));
                                            }
                                        } else {
                                            return Promise.reject(t("kisi.invalidTckn"));
                                        }
                                    } else {
                                        return Promise.reject(t("kisi.invalidTckn"));
                                    }
                                },
                                message: "",
                            },
                        ]}
                    >
                        <Input
                            placeholder={t("kisi.TCnoHint")}
                            maxLength={11}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default KisiEkle;
