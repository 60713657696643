import { Form, Button, DatePicker, Input, Layout, Modal, notification, Popconfirm, Spin } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { useAcilDurumPlaniGetir, useAcilDurumPlaniKaydet, useAcilDurumPlaniSil } from "../hooks/useAcilDurumPlani";
import { useKisininYetkileriniGetir } from "../hooks/useKisi";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import KisiSelect from "../components/KisiSelect";

const { Content } = Layout;
const { TextArea } = Input;

function AcilDurumPlani() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [api, contextHolder] = notification.useNotification();

    const { kisininYetkileri } = useKisininYetkileriniGetir();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [versiyonModalOpen, setVersiyonModalOpen] = useState(false);
    const [durum, setDurum] = useState();
    const [taslakSahibi, setTaslakSahibi] = useState();
    const [sonrakiID, setSonrakiID] = useState();
    const [adpID, setAdpID] = useState();

    const { kisiID } = useGetUserInfo();
    const { acilDurumPlani } = useAcilDurumPlaniGetir(id);
    const { acilDurumPlaniSil } = useAcilDurumPlaniSil(id);
    const { acilDurumPlaniKaydet } = useAcilDurumPlaniKaydet();
    const [kayitDegisebilir, setKayitDegisebilir] = useState();
    const [kisiYazabilir, setKisiYazabilir] = useState();
    const [kisiSilebilir, setKisiSilebilir] = useState();
    const [versiyonArttir, setVersiyonArttir] = useState(false);
    const [versiyonYayinla, setVersiyonYayinla] = useState(false);

    const versiyonYayinlaClick = () => {
        setIsPending(true);
        console.log("versiyonYayinlaClick");
        acilDurumPlaniKaydet({
            adpID: adpID,
            kayitID: id,
            durum: "S",
            versiyonKodu: form.getFieldValue("versiyonKodu"),
            versiyonDetay: form.getFieldValue("versiyonDetay"),
        }).then(() => {
            navigate("/adpList");
        });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    const yeniVersiyonOlusturClick = () => {
        setIsPending(true);
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"];
        console.log("accessToken : ", accessToken);
        // mevcut kaydın bir kopyası oluşturuluyor
        fetch(`http://89.19.24.18:3001/AcilDurumPlani/klon`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({ kayitID: id }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                id = data.id;
                setDurum("T");
                setVersiyonArttir(false);
                setVersiyonYayinla(false);
                navigate(`/acilDurumPlani/${data.id}`);
            });

        setIsPending(false);
    };

    const acilDurumPlaniKaydetButon =
        kayitDegisebilir && kisiYazabilir ? (
            <Button key="acilDurumPlaniKaydetButon" type="primary" htmlType="submit" loading={isPending}>
                {t("genel.kaydet")}
            </Button>
        ) : null;

    const acilDurumPlaniSilButon = kisiSilebilir && kayitDegisebilir && (
        <Popconfirm
            key="acilDurumPlaniSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                acilDurumPlaniSil(id);
                navigate("/adpList");
            }}
            style={{ width: 400 }}
        >
            <Button key="acilDurumPlaniSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    const versiyonArttirButon = versiyonArttir && (
        <Button key="adpVersiyonArttirButon" type="primary" disabled={false} onClick={yeniVersiyonOlusturClick}>
            {"Yeni Versiyon Oluştur"}
        </Button>
    );

    const versiyonYayinlaButon = versiyonYayinla && (
        <Button
            key="adpVersiyonYayinlaButon"
            type="primary"
            disabled={false}
            onClick={() => {
                setVersiyonModalOpen(true);
            }}
        >
            {"Versiyon Yayınla"}
        </Button>
    );

    useEffect(() => {
        if (acilDurumPlani) {
            form.setFieldsValue({
                acilDurum: acilDurumPlani.acilDurum,
                hazirlamaTarihi: acilDurumPlani.hazirlamaTarihi ? dayjs(acilDurumPlani.hazirlamaTarihi) : null,
                gecerlilikTarihi: acilDurumPlani.gecerlilikTarihi ? dayjs(acilDurumPlani.gecerlilikTarihi) : null,
                hazirlayanlar: acilDurumPlani.hazirlayanlar,
                onaylayan: acilDurumPlani.onaylayan,
                onleyiciTedbirler: acilDurumPlani.onleyiciTedbirler,
                sinirlayiciTedbirler: acilDurumPlani.sinirlayiciTedbirler,
                mudahaleYontemleri: acilDurumPlani.mudahaleYontemleri,
            });
            setVersiyonArttir(false);
            setVersiyonYayinla(false);
            setAdpID(acilDurumPlani.adpID);
            setDurum(acilDurumPlani.durum);
            setSonrakiID(acilDurumPlani.sonrakiID);
            setTaslakSahibi(acilDurumPlani.taslakSahibi);
            setLoading(false);
        }
    }, [acilDurumPlani]);

    useEffect(() => {
        if (kisininYetkileri.includes("ADMIN")) {
            setKisiYazabilir(true);
            setKisiSilebilir(true);
        }
        if (kisininYetkileri.includes("ADUP")) {
            setKisiYazabilir(true);
            setKisiSilebilir(true);
        }
        if (kisininYetkileri.includes("ADUPKD")) {
            setKisiYazabilir(true);
        }
        if (kisininYetkileri.includes("ADUPKS")) {
            setKisiSilebilir(true);
        }

        if (!id) {
            setKayitDegisebilir(true);
            setKisiSilebilir(false);
        } else if (durum == "T") {
            //taslak durumundaysa
            if (taslakSahibi == kisiID) {
                // ve taslak sahibi bu kişiyse
                setKayitDegisebilir(true);
                setVersiyonYayinla(true);
            }
        } else if (durum == "S") {
            if (sonrakiID == null) {
                setVersiyonArttir(true);
            }
        }
    }, [kisininYetkileri, durum, taslakSahibi, sonrakiID, id, versiyonArttir, versiyonYayinla]);

    const onSubmit = (e) => {
        if (id) e.kayitID = id;
        setIsPending(true);
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"];
        console.log("accessToken : ", accessToken);
        fetch(`http://89.19.24.18:3001/AcilDurumPlani/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                navigate(`/acilDurumPlani/${data.id}`);
            });

        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!(kisiYazabilir && kayitDegisebilir)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="acilDurumPlaniKmyHeader"
                        caller="sayfaBaslik.acilDurumPlani"
                        buttons={[versiyonArttirButon, versiyonYayinlaButon, acilDurumPlaniKaydetButon, acilDurumPlaniSilButon]}
                        backURL="/adpList"
                    />
                    {contextHolder}
                    <Form.Item name="hazirlamaTarihi" label={t("adp.hazirlamaTarihi")}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item name="gecerlilikTarihi" label={t("adp.gecerlilikTarihi")}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item name="acilDurum" label={t("adp.acilDurum")}>
                        <TextArea placeholder={t("adp.acilDurumHint")} autoSize={true} />
                    </Form.Item>
                    <Form.Item name="onleyiciTedbirler" label={t("adp.onleyiciTedbirler")}>
                        <TextArea placeholder={t("adp.onleyiciTedbirler")} autoSize={true} />
                    </Form.Item>
                    <Form.Item name="sinirlayiciTedbirler" label={t("adp.sinirlayiciTedbirler")}>
                        <TextArea placeholder={t("adp.sinirlayiciTedbirler")} autoSize={true} />
                    </Form.Item>
                    <Form.Item name="mudahaleYontemleri" label={t("adp.mudahaleYontemleri")}>
                        <TextArea placeholder={t("adp.mudahaleYontemleri")} autoSize={true} />
                    </Form.Item>
                    <KisiSelect name="hazirlayanlar" label={t("adp.hazirlayanlar")} mode="tags" />
                    <KisiSelect name="onaylayan" label={t("adp.onaylayan")} />
                    <Modal
                        key="versiyonYayinlaModalForm"
                        open={versiyonModalOpen}
                        onOk={() => {
                            setVersiyonModalOpen(false);
                        }}
                        onCancel={() => {
                            setVersiyonModalOpen(false);
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setVersiyonModalOpen(false);
                                }}
                                style={{ marginRight: 20 }}
                            >
                                {t("genel.vazgec")}
                            </Button>,
                            <Button
                                key="ok"
                                type="primary"
                                form="kisiEkleForm"
                                onClick={() => {
                                    versiyonYayinlaClick();
                                }}
                            >
                                {t("genel.kaydet")}
                            </Button>,
                        ]}
                    >
                        <Form.Item name="versiyonKodu" label={t("adp.versiyonKodu")}>
                            <TextArea placeholder={t("adp.versiyonKoduHint")} autoSize={true} />
                        </Form.Item>
                        <Form.Item name="versiyonDetay" label={t("adp.versiyonDetay")}>
                            <TextArea placeholder={t("adp.versiyonDetayHint")} autoSize={true} />
                        </Form.Item>
                    </Modal>
                </Form>
            </Content>
        </Layout>
    );
}

export default AcilDurumPlani;
