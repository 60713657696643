import { Card, Divider, Layout, List, notification, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import {
    ExperimentOutlined,
    FireOutlined,
    AuditOutlined,
    GlobalOutlined,
    MailOutlined,
    ShopOutlined,
    TeamOutlined,
    TruckOutlined,
    WarningOutlined,
    QuestionOutlined,
} from "@ant-design/icons";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import FireExtinguisherOutlinedIcon from "@mui/icons-material/FireExtinguisherOutlined";
import TheaterComedyOutlinedIcon from "@mui/icons-material/TheaterComedyOutlined";
import StairsOutlinedIcon from "@mui/icons-material/StairsOutlined";
import KmyHeader from "./KmyHeader";
import AnaMenu from "./AnaMenu";
import FirmaLogosu from "../components/FirmaLogosu";
import KisiResmi from "../components/KisiResmi";

const { Meta } = Card;
const { Header, Content } = Layout;

function Home() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [user, setUser] = useState({});
    const [uetds, setUetds] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser === null) {
                navigate("/login");
                localStorage.removeItem("auth");
                localStorage.removeItem("kmyToken");
            }
        });
    }, []);

    return (
        <Layout key="homeLayout">
            <KmyHeader key="homeHeader" caller="sayfaBaslik.home" buttons={[<KisiResmi />, <FirmaLogosu />]} />
            <Layout>
                <AnaMenu />
                <Content key="homeContent">
                    {contextHolder}
                    <Row key="homeRow" style={{ padding: 20 }}>
                        <Divider orientation="left">Ortak Bilgiler</Divider>
                        <Card
                            key="kisiList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/kisiList");
                            }}
                        >
                            <Meta avatar={<TeamOutlined style={{ fontSize: 24, color: "red" }} />} title={t("kisi.kisi")} />
                        </Card>
                        <Card
                            key="firmaList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/firmaList");
                            }}
                        >
                            <Meta avatar={<ShopOutlined style={{ fontSize: 24, color: "red" }} />} title={t("firma.firma")} />
                        </Card>
                        <Card
                            key="rolList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/rolList");
                            }}
                        >
                            <Meta avatar={<TheaterComedyOutlinedIcon style={{ fontSize: 24, color: "red" }} />} title={t("sayfaBaslik.rolList")} />
                        </Card>

                        <Divider orientation="left">Tehlikeli Madde</Divider>
                        <Card
                            key="sdsList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/sdsList");
                            }}
                        >
                            <Meta avatar={<ExperimentOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.sdsList")} />
                        </Card>
                        <Card
                            key="tableA"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/tableA");
                            }}
                        >
                            <Meta avatar={<ExperimentOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.tableA")} />
                        </Card>
                        <Card
                            key="envanterList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/envanterList");
                            }}
                        >
                            <Meta avatar={<QuestionOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.envanterList")} />
                        </Card>
                        <Card
                            key="tespitList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/tespitList");
                            }}
                        >
                            <Meta avatar={<QuestionOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.tespitList")} />
                        </Card>
                        <Card
                            key="adpList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/adpList");
                            }}
                        >
                            <Meta avatar={<QuestionOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.adpList")} />
                        </Card>
                        <Card
                            key="riskList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/riskList");
                            }}
                        >
                            <Meta avatar={<WarningOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.riskAnalizi")} />
                        </Card>
                        <Card
                            key="tatbikatList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/tatbikatList");
                            }}
                        >
                            <Meta avatar={<QuestionOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.tatbikatList")} />
                        </Card>

                        <Divider orientation="left">Aksiyon</Divider>
                        <Card
                            key="aksiyonList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/aksiyonList");
                            }}
                        >
                            <Meta avatar={<QuestionOutlined style={{ fontSize: 24, color: "red" }} />} title={t("sayfaBaslik.aksiyonList")} />
                        </Card>
                        <Card
                            key="bildirimList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/bildirimList");
                            }}
                        >
                            <Meta avatar={<MailOutlined style={{ fontSize: 24, color: "red" }} />} title={t("sayfaBaslik.bildirim")} />
                        </Card>

                        <Divider orientation="left">Eğitim</Divider>
                        <Card
                            key="kursList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/kursList");
                            }}
                        >
                            <Meta avatar={<CollectionsBookmarkOutlinedIcon style={{ fontSize: 24 }} />} title={t("sayfaBaslik.kursList")} />
                        </Card>
                        <Card
                            key="egitimList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/egitimList");
                            }}
                        >
                            <Meta avatar={<SchoolOutlinedIcon style={{ fontSize: 24 }} />} title={t("sayfaBaslik.egitimList")} />
                        </Card>

                        <Divider orientation="left">Ekipman</Divider>
                        <Card
                            key="ekipmanList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/ekipmanList");
                            }}
                        >
                            <Meta avatar={<QuestionOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.ekipmanList")} />
                        </Card>
                        <Card
                            key="yanginTupuList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/yanginTupuList");
                            }}
                        >
                            <Meta avatar={<FireOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.yanginTupuList")} />
                        </Card>
                        <Card
                            key="yanginDolabiList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/yanginDolabiList");
                            }}
                        >
                            <Meta avatar={<FireOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.yanginDolabiList")} />
                        </Card>
                        <Card
                            key="merdivenList"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/merdivenList");
                            }}
                        >
                            <Meta avatar={<StairsOutlinedIcon style={{ fontSize: 24 }} />} title={t("sayfaBaslik.merdivenList")} />
                        </Card>

                        <Divider orientation="left">UETDS</Divider>
                        <Card
                            key="uetdsTc"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/uetdsTc");
                            }}
                        >
                            <Meta avatar={<TeamOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.uetdsTc")} />
                        </Card>
                        <Card
                            key="uetdsPlaka"
                            hoverable
                            style={{ width: 240, margin: 5 }}
                            onClick={() => {
                                navigate("/uetdsPlaka");
                            }}
                        >
                            <Meta avatar={<TruckOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.uetdsPlaka")} />
                        </Card>
                    </Row>
                </Content>
            </Layout>
        </Layout>
    );
}

export default Home;
