import { Button, Image, Input, Layout, Modal, Space, Spin, Table, Typography } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { useSdsListele } from "../hooks/useSds";
import KmyHeader from "./KmyHeader";
const { Header, Content } = Layout;

function SdsList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const { sdsListesi } = useSdsListele();

    const addButton = (
        <Button
            key="addButton"
            type="primary"
            htmlType="submit"
            loading={isPending}
            size="small"
            onClick={() => {
                navigate("/Sds");
            }}
        >
            {t("genel.ekle")}
        </Button>
    );

    useEffect(() => {
        setDataSource(sdsListesi);
        setFilteredSource(sdsListesi);
        setIsPending(false);
    }, [sdsListesi]);

    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        if (searchText) {
            setFilteredSource(
                dataSource.filter((item) => {
                    const acilDurum = item.acilDurum ? item.acilDurum.toLowerCase() : "";
                    return acilDurum.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const columns = [
        {
            title: "",
            width: 40,
            render: (p) => (
                <Button
                    type="text"
                    key="kayitID"
                    icon={<EyeOutlined />}
                    style={{ marginRight: -15 }}
                    onClick={() => {
                        navigate(`/sds/${p.kayitID}`);
                    }}
                />
            ),
        },
        {
            title: t("sds.maddeAdi"),
            dataIndex: "maddeAdi",
            render: (saha, kayit) => {
                return (
                    <Typography.Text
                        key={kayit.kayitID}
                        style={{
                            color: kayit.durum === "S" ? "#000" : "#888",
                            textDecoration: kayit.durum === "E" ? "line-through" : "none",
                        }}
                    >
                        {saha}
                    </Typography.Text>
                );
            },
            sorter: (a, b) => a.maddeAdi.localeCompare(b.maddeAdi),
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log("params", sorter, filters);
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout key="sdsLayout">
            <Content key="sdsContent" style={{ padding: "0px" }}>
                <KmyHeader key="sdsListHeader" caller="sayfaBaslik.sdsList" buttons={[addButton]} backURL="/" />
                <Input
                    key="searchBox"
                    placeholder="search"
                    prefix={<SearchOutlined />}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Table key="sdsList" dataSource={filteredSource} columns={columns} onChange={onChange} />
            </Content>
        </Layout>
    );
}

export default SdsList;
