import { Button, Image, Input, Layout, Modal, Space, Spin, Table, Typography } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { useAcilDurumPlaniListele } from "../hooks/useAcilDurumPlani";
import KmyHeader from "./KmyHeader";
const { Header, Content } = Layout;

function AdpList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const { acilDurumPlaniListesi } = useAcilDurumPlaniListele();

    const addButton = (
        <Button
            key="addButton"
            type="primary"
            htmlType="submit"
            loading={isPending}
            size="small"
            onClick={() => {
                navigate("/AcilDurumPlani");
            }}
        >
            {t("genel.ekle")}
        </Button>
    );

    useEffect(() => {
        setDataSource(acilDurumPlaniListesi);
        setFilteredSource(acilDurumPlaniListesi);
        setIsPending(false);
    }, [acilDurumPlaniListesi]);

    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        if (searchText) {
            setFilteredSource(
                dataSource.filter((item) => {
                    const acilDurum = item.acilDurum ? item.acilDurum.toLowerCase() : "";
                    return acilDurum.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const columns = [
        {
            title: "",
            width: 40,
            render: (p) => (
                <Button
                    type="text"
                    key="kayitID"
                    icon={<EyeOutlined />}
                    style={{ marginRight: -15 }}
                    onClick={() => {
                        navigate(`/acilDurumPlani/${p.kayitID}`);
                    }}
                />
            ),
        },
        {
            title: t("adp.acilDurum"),
            dataIndex: "acilDurum",
            render: (saha, kayit) => {
                return (
                    <Typography.Text
                        key={kayit.kayitID}
                        style={{
                            color: kayit.durum === "S" ? "#000" : "#888",
                            textDecoration: kayit.durum === "E" ? "line-through" : "none",
                        }}
                    >
                        {saha}
                    </Typography.Text>
                );
            },
            sorter: (a, b) => a.acilDurum.localeCompare(b.acilDurum),
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log("params", sorter, filters);
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout key="adpLayout">
            <Content key="adpContent" style={{ padding: "0px" }}>
                <KmyHeader key="adpListHeader" caller="sayfaBaslik.adpList" buttons={[addButton]} backURL="/" />
                <Input
                    key="searchBox"
                    placeholder="search"
                    prefix={<SearchOutlined />}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Table key="adpList" dataSource={filteredSource} columns={columns} onChange={onChange} />
            </Content>
        </Layout>
    );
}

export default AdpList;
