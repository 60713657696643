import { Card, Layout, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import AnaMenu from "./AnaMenu";
import FirmaLogosu from "../components/FirmaLogosu";
import KisiResmi from "../components/KisiResmi";
import { VictoryPie, VictoryTheme, VictoryLabel } from "victory";
import { Container } from "@mui/material";

const { Meta } = Card;
const { Header, Content } = Layout;

function Taseron() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();

    const data = [
        { x: "Cats", y: 35 },
        { x: "Dogs", y: 40 },
        { x: "Birds", y: 55 },
    ];

    const props = {
        data,
        xField: "x",
        yField: "y",
    };

    return (
        <Layout key="homeLayout">
            <KmyHeader key="taseronHeader" caller="sayfaBaslik.taseron" buttons={[<KisiResmi />, <FirmaLogosu />]} />
            <Layout>
                <Content key="homeContent">
                    {contextHolder}
                    <Card title="Deneme" style={{ width: 400, height: 400 }}>
                        <VictoryPie
                            style={{ width: 500 }}
                            innerRadius={30}
                            cornerRadius={5}
                            labelPosition="centroid"
                            labelPlacement="perpendicular"
                            padding={{ top: 20, bottom: 20, left: 20, right: 20 }}
                            data={[
                                { x: "Onaylı", y: 35 },
                                { x: "Bekleyen", y: 40 },
                                { x: "Eksik", y: 55 },
                            ]}
                            theme={VictoryTheme.clean}
                        />
                    </Card>
                </Content>
            </Layout>
        </Layout>
    );
}

export default Taseron;
