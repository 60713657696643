import { Form, Button, Col, Drawer, Layout, List, notification, Row, Upload } from "antd";
import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import locale from "antd/es/date-picker/locale/tr_TR";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import { useKisininYetkileriniGetir } from "../hooks/useKisi";

import { v4 } from "uuid";
import BelgeGuncelle from "./BelgeGuncelle";
const { Header, Content } = Layout;

function FirmaBelgeOnayList() {
    const { t } = useTranslation();

    const { firmaID } = useGetUserInfo();
    const [docProps, setDocProps] = useState();
    const [docSource, setDocSource] = useState([]);
    const [filteredDocSource, setFilteredDocSource] = useState([]);
    const [belgeler, setBelgeler] = useState();

    const [docModalOpen, setDocModalOpen] = useState(false);
    const [docbelgeTipiID, setDocbelgeTipiID] = useState();
    const [docDesc, setDocDesc] = useState();
    const [gecerlikTarihi, setgecerlikTarihi] = useState();
    const [verilisTarihi, setverilisTarihi] = useState();
    const [fileList, setFileList] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const { kisininYetkileri } = useKisininYetkileriniGetir();

    useEffect(() => {
        belgeleriGetir();
    }, []);

    useEffect(() => {
        setDocSource(belgeler);
        setFilteredDocSource(belgeler);
    }, [belgeler]);

    const belgeleriGetir = async () => {
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
        let unsubscribe;
        fetch(`http://89.19.24.18:3001/Belge/ufukuu/KISI`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                unsubscribe = data;
                console.log("data : ", data);
                setBelgeler(data);
            });

        setBelgeler(unsubscribe);
    };

    return (
        <Layout style={{ padding: 30 }}>
            <Content style={{ padding: "0px" }}>
                <Row style={{ width: "100%" }}>
                    <Col style={{ width: "50%" }}>
                        <List
                            style={{ marginLeft: 20, marginTop: 20 }}
                            dataSource={filteredDocSource}
                            renderItem={(item) => (
                                <List.Item
                                    key={item.id}
                                    style={{ cursor: "pointer", backgroundColor: item.id == docProps.id ? "lightblue" : null, padding: 10 }}
                                    onClick={() => {
                                        console.log("clicked : ", item);
                                        setDocProps({
                                            id: item.id,
                                            belgeTipiID: item.belgeTipiID,
                                            belgeIsmi: item.belgeIsmi,
                                            durumAciklamasi: item.durumAciklamasi,
                                            verilisTarihi: item.verilisTarihi,
                                            gecerlikTarihi: item.gecerlikTarihi,
                                        });
                                        setDocModalOpen(true);
                                    }}
                                >
                                    {item.belgeIsmi}.
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col style={{ width: "50%" }}>
                        <BelgeGuncelle fieldValues={docProps} id={firmaID} />
                        <Button
                            key="cancel"
                            onClick={() => {
                                setDocModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>

                        <Button
                            key="ok"
                            type="primary"
                            form="docUpdateForm"
                            htmlType="submit"
                            onClick={() => {
                                setDocModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default FirmaBelgeOnayList;
