import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import meslekiYeterlilik from "../constant/meslekiYeterlilik.json";
import ImgCrop from "antd-img-crop";
import PhoneInput, { locale as phoneLocale } from "antd-phone-input";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import {
    Alert,
    Avatar,
    Button,
    Cascader,
    ConfigProvider,
    DatePicker,
    Form,
    Input,
    Layout,
    List,
    message,
    Modal,
    notification,
    Popconfirm,
    Select,
    Space,
    Spin,
    Tabs,
    Tag,
    Tooltip,
    Upload,
} from "antd";
import {
    CalendarOutlined,
    CheckSquareFilled,
    EditOutlined,
    LoadingOutlined,
    MailOutlined,
    PhoneOutlined,
    PlusOutlined,
    IdcardFilled,
} from "@ant-design/icons";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import { useKisiyiGetir, useKisiyiSil, useKisininYetkileriniGetir } from "../hooks/useKisi";
import { useKisiFirmaGetir } from "../hooks/useKisiFirma";
import KmyHeader from "./KmyHeader";
import KisiFirmaYaz from "./KisiFirmaYaz";
import BolumSelect from "../components/BolumSelect";
import SahaDegerleri from "../components/SahaDegerleri";
import { useFirmalariGetir } from "../hooks/useFirma";
import { PDFDownloadLink } from "@react-pdf/renderer";
import TcNoInput from "../components/TcNoInput";
import Belgeler from "./Belgeler";
import SgkGorevKodu from "../components/SgkGorevKodu";

const { Header, Content } = Layout;

function KisiGuncelle() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    let { id } = useParams();
    const [kisiFirmaModalOpen, setKisiFirmaModalOpen] = useState(false);
    const { firmalar } = useFirmalariGetir();
    const [firmaOptions, setFirmaOptions] = useState([]);

    const { kisi } = useKisiyiGetir(id);
    const { kisiFirma } = useKisiFirmaGetir(id);
    const { kisininYetkileri } = useKisininYetkileriniGetir();
    const { kisiyiSil } = useKisiyiSil(id);

    const [form] = Form.useForm();
    const [isPending, setIsPending] = useState(false);
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(true);
    const [avatarURL, setAvatarURL] = useState();
    const [kisiFirmaDataSource, setKisiFirmaDataSource] = useState([]);
    const [yetkiProps, setYetkiProps] = useState();
    const [adSoyad, setAdSoyad] = useState();
    const [formPasif, setFormPasif] = useState();

    const [user, setUser] = useState({});

    useEffect(() => {
        setFormPasif(true);
        if (kisininYetkileri.includes("ADMIN")) setFormPasif(false);
        if (kisininYetkileri.includes("KISIKY")) setFormPasif(false);
        if (kisininYetkileri.includes("KISI")) setFormPasif(false);
    }, [kisininYetkileri]);

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
    }, []);

    useEffect(() => {
        if (firmalar) {
            const dropdownItemsFirma = firmalar.map((item) => ({ value: String(item.firmaID), label: String(item.firmaismi) }));
            setFirmaOptions(dropdownItemsFirma);
        }
    }, [firmalar]);

    const beforeUpload = (file) => {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error(t("genel.lessThan10"));
        }
        return isLt10M;
    };

    const handleUploadChange = (info) => {
        if (info.file.status === "uploading") {
            //setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            console.log("image upload done");
        }
    };

    const avatarUpload = async ({ onError, onSuccess, file }) => {
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
        const parts = file.name.split(".");
        const fileExt = parts[parts.length - 1];
        const formData = new FormData();
        formData.append("doc", file, `avatar.png`);
        const createXHR = () => new XMLHttpRequest();
        console.log("formdata : ", formData);
        fetch(`http://89.19.24.18:3001/Kisi/${id}`, {
            body: formData,
            createXHR,
            method: "POST",
        })
            .then((response) => {
                console.log("cevap geldi : ", response);
                return response.json();
            })
            .then((res) => {
                console.log("avatar atandı : ", res.body);
                setAvatarURL(res.body);
            });
        setLoading(false);
    };

    function birKisiSil() {
        kisiyiSil(id);
        navigate("/kisiList");
    }

    useEffect(() => {
        if (kisiFirma) {
            setKisiFirmaDataSource(kisiFirma);
        }
    }, [kisiFirma]);

    const kimlikButton = (
        <Tooltip title={t("kisi.kimlikKarti")}>
            <Button
                type="text"
                size="large"
                fileName="kisiKimlik.pdf"
                onClick={() => {
                    window.open(`/kisiKimlik/${id}`, "_blank")?.focus();
                }}
                style={{ marginTop: 20 }}
                visible={!isPending}
                icon={<IdcardFilled style={{ color: "white", fontSize: 30 }} />}
            />
        </Tooltip>
    );

    const savePersonButton = (kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("KISIKY") || kisininYetkileri.includes("KISI")) && (
        <Button type="primary" htmlType="submit" loading={isPending} danger={errMessage} size="small">
            {t("genel.kaydet")}
        </Button>
    );

    const deletePersonButton =
        kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("KISIKS") || kisininYetkileri.includes("KISI") ? (
            <Popconfirm
                placement="topRight"
                title={t("genel.eminMisin")}
                description={t("genel.silinecek")}
                okText={t("genel.sil")}
                cancelText={t("genel.vazgec")}
                onConfirm={() => {
                    birKisiSil();
                }}
                style={{ width: 400 }}
            >
                <Button type="primary" danger size="small">
                    {t("genel.sil")}
                </Button>
            </Popconfirm>
        ) : null;

    useEffect(() => {
        if (kisi) {
            const checkThat = kisi.email;
            if (checkThat) {
                form.setFieldsValue({
                    email: kisi.email,
                    firmaID: kisi.firmaID,
                    telefonNo: kisi.telefonNo,
                    TCno: kisi.TCno,
                    isim: kisi.isim,
                    soyad: kisi.soyad,
                    SGKgorevKodu: kisi.SGKgorevKodu,
                    unvan: kisi.unvan,
                    meslekiYeterlilikKodu: kisi.meslekiYeterlilikKodu,
                    dogumYili: kisi.dogumYili,
                    cinsiyet: kisi.cinsiyet,
                    kanGurubu: kisi.kanGurubu,
                    evTelefonu: kisi.evTelefonu,
                    acilDurumEkibi: kisi.acilDurumEkibi,
                    acilDurumKisi1: kisi.acilDurumKisi1,
                    acilDurumYakinlik1: kisi.acilDurumYakinlik1,
                    acilDurumTelefon1: kisi.acilDurumTelefon1,
                    acilDurumKisi2: kisi.acilDurumKisi2,
                    acilDurumYakinlik2: kisi.acilDurumYakinlik2,
                    acilDurumTelefon2: kisi.acilDurumTelefon2,
                    firmadakiUsername: kisi.firmadakiUsername,
                    firmadakiSicilno: kisi.firmadakiSicilno,
                    dahiliNumara: kisi.dahiliNumara,
                    meslekiYeterlilik: kisi.meslekiYeterlilik,
                    egitimDurumu: kisi.egitimDurumu,
                    okul: kisi.okul,
                    gorevler: kisi.gorevler,
                    bolumID: kisi.bolumID,
                    sorumluluk: kisi.sorumluluk ? kisi.sorumluluk : null,
                    girisTarihi: kisi.girisTarihi ? dayjs(kisi.girisTarihi) : null,
                    cikisTarihi: kisi.cikisTarihi ? dayjs(kisi.cikisTarihi) : null,
                });
                setAdSoyad(`${kisi.isim} ${kisi.soyad}`);
                setLoading(false);
                setAvatarURL(kisi.fotoURL);
            }
        }
    }, [kisi]);

    useEffect(() => {}, [avatarURL]);

    const onSubmit = (e) => {
        setIsPending(true);
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
        fetch(`http://89.19.24.18:3001/Kisi/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                setIsPending(false);
                api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
            })
            .catch((err) => {
                console.error(err);
                if (err.toString().includes("Failed to fetch")) {
                    setErrMessage("Sunucuya bağlanılamadı");
                } else {
                    setErrMessage("HATA");
                }
                api["error"]({ message: errMessage, placement: "topRight" });
                setIsPending(false);
                return;
            });
    };

    const headerButtons = [kimlikButton, savePersonButton, deletePersonButton];

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={formPasif}
                    autoComplete="off"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 11 }}
                    onFinish={(values) => onSubmit(values)}
                    onFinishFailed={(error) => {
                        console.error("onFinishFailed : " + { error });
                    }}
                >
                    <KmyHeader caller={adSoyad} buttons={headerButtons} backURL="/kisiList" />

                    {contextHolder}
                    <Space direction="horizontal" style={{ width: "100%", marginBottom: 20, justifyContent: "center" }}>
                        <ImgCrop
                            showGrid="true"
                            rotationSlider="true"
                            modalTitle="Resmi düzenle"
                            modalCancel={t("genel.vazgec")}
                            modalOk={t("genel.kaydet")}
                            cropShape="round"
                        >
                            <Upload
                                name="avatar"
                                accept=".jpg, .jpeg, .png"
                                listType="picture-circle"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={avatarUpload}
                                beforeUpload={beforeUpload}
                                onChange={handleUploadChange}
                            >
                                {avatarURL ? (
                                    <Avatar src={`${avatarURL}`} alt="avatar" style={{ width: "100%", height: "100%" }} />
                                ) : (
                                    <button style={{ border: 1, background: "none" }} type="button">
                                        {loading ? <LoadingOutlined style={{ cursor: "pointer" }} /> : <PlusOutlined style={{ cursor: "pointer" }} />}
                                        <div style={{ marginTop: 8, marginBottom: -20, cursor: "pointer" }}>{t("genel.yukle")}</div>
                                    </button>
                                )}
                            </Upload>
                        </ImgCrop>
                    </Space>
                    <Tabs
                        centered
                        type="card"
                        defaultActiveKey="1"
                        items={[
                            {
                                key: "1",
                                label: t("kisi.tabGenel"),
                                children: (
                                    <>
                                        <Form.Item name="email" label={t("kisi.email")} rules={[{ required: true, message: t("genel.gerekli") }]}>
                                            <Input
                                                prefix={<MailOutlined />}
                                                placeholder={t("kisi.email")}
                                                disabled={true}
                                                suffix={
                                                    kisi.nviOnayladi ? (
                                                        <Tooltip title={t("kisi.emailDogrulandi")}>
                                                            <CheckSquareFilled style={{ fontSize: "20px", color: "#0A0" }} />
                                                        </Tooltip>
                                                    ) : null
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item name="firmaID" label={t("kisi.firmaIsmi")} style={{ marginTop: 20 }}>
                                            <Select
                                                showSearch
                                                placeholder={t("genel.seciniz")}
                                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {firmaOptions.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        {(kisininYetkileri.includes("ADMIN") ||
                                            kisininYetkileri.includes("KISI") ||
                                            kisininYetkileri.includes("KISIKD")) && (
                                            <ConfigProvider locale={phoneLocale("trTR")}>
                                                <Form.Item name="telefonNo" label={t("kisi.telefonNo")}>
                                                    <PhoneInput
                                                        defaultCountry={"TR"}
                                                        placeholder={t("kisi.telefonNoHint")}
                                                        enableSearch={true}
                                                        disabled={formPasif}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider>
                                        )}
                                        {(kisininYetkileri.includes("ADMIN") ||
                                            kisininYetkileri.includes("KISI") ||
                                            kisininYetkileri.includes("KISIKD")) && (
                                            <TcNoInput name="TCno" label={t("kisi.TCno")} hint={t("kisi.TCno")} onayli={kisi.nviOnayladi} />
                                        )}
                                        <Form.Item
                                            name="isim"
                                            label={t("kisi.isim")}
                                            rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                                        >
                                            <Input
                                                placeholder={t("kisi.isim")}
                                                onInput={(e) => {
                                                    var result = "";
                                                    var words = e.target.value.split(" ");
                                                    words.forEach(function (value, index, array) {
                                                        value = value.charAt(0).toUpperCase() + value.slice(1);
                                                        result = result.trimStart() + " " + value;
                                                    });
                                                    e.target.value = result.trimStart();
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="soyad"
                                            label={t("kisi.soyad")}
                                            rules={[
                                                { required: true, message: t("genel.gerekli") },
                                                { whitespace: true },
                                                {
                                                    transform: (v) => {
                                                        v = v.toUpperCase();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input placeholder={t("kisi.soyad")} onInput={(e) => (e.target.value = e.target.value.toUpperCase())} />
                                        </Form.Item>
                                        <BolumSelect name="bolumID" label={t("kisi.bolum")} hint={t("kisi.bolumHint")} />

                                        <SahaDegerleri name="unvan" id="kisi.unvan" label={t("kisi.unvan")} />

                                        <Form.Item name="dogumYili" label={t("kisi.dogumYili")}>
                                            <Input
                                                prefix={<CalendarOutlined />}
                                                placeholder={t("kisi.dogumYili")}
                                                maxLength={4}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item name="cinsiyet" label={t("kisi.cinsiyet")}>
                                            <Select placeholder={t("kisi.cinsiyet")}>
                                                <Select.Option value="E">{t("kisi.erkek")}</Select.Option>
                                                <Select.Option value="K">{t("kisi.kadin")}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <SahaDegerleri mode="tags" name="gorevler" id="kisi.gorev" label={t("kisi.gorev")} />
                                    </>
                                ),
                            },
                            {
                                key: "2",
                                label: t("kisi.tabAcil"),
                                children: (
                                    <>
                                        <Form.Item name="kanGurubu" label={t("kisi.kanGurubu")} requiredMark="optional">
                                            <Select placeholder={t("kisi.kanGurubu")}>
                                                <Select.Option value="0-">0-</Select.Option>
                                                <Select.Option value="0+">0+</Select.Option>
                                                <Select.Option value="A-">A-</Select.Option>
                                                <Select.Option value="A+">A+</Select.Option>
                                                <Select.Option value="B-">B-</Select.Option>
                                                <Select.Option value="B+">B+</Select.Option>
                                                <Select.Option value="AB-">AB-</Select.Option>
                                                <Select.Option value="AB+">AB+</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        {(kisininYetkileri.includes("ADMIN") ||
                                            kisininYetkileri.includes("KISI") ||
                                            kisininYetkileri.includes("KISIKD")) && (
                                            <ConfigProvider locale={phoneLocale("trTR")}>
                                                <Form.Item name="evTelefonu" label={t("kisi.evTelefonu")}>
                                                    <PhoneInput
                                                        defaultCountry={"TR"}
                                                        placeholder={t("kisi.telefonNoHint")}
                                                        enableSearch={true}
                                                        disabled={formPasif}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider>
                                        )}
                                        <SahaDegerleri
                                            name="acilDurumEkibi"
                                            id="kisi.acilDurumEkibi"
                                            label={t("kisi.acilDurumEkibi")}
                                            hint={t("kisi.acilDurumEkibiHint")}
                                        />

                                        <Form.Item name="acilDurumKisi1" label={t("kisi.acilDurumKisi1")}>
                                            <Input placeholder={t("kisi.acilDurumKisiHint")} />
                                        </Form.Item>
                                        <Form.Item name="acilDurumYakinlik1" label={t("kisi.acilDurumYakinlik")}>
                                            <Input placeholder={t("kisi.acilDurumYakinlikHint")} />
                                        </Form.Item>
                                        {(kisininYetkileri.includes("ADMIN") ||
                                            kisininYetkileri.includes("KISI") ||
                                            kisininYetkileri.includes("KISIKD")) && (
                                            <ConfigProvider locale={phoneLocale("trTR")}>
                                                <Form.Item name="acilDurumTelefon1" label={t("kisi.telefonNo")}>
                                                    <PhoneInput
                                                        defaultCountry={"TR"}
                                                        placeholder={t("kisi.telefonNoHint")}
                                                        enableSearch={true}
                                                        disabled={formPasif}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider>
                                        )}
                                        <Form.Item name="acilDurumKisi2" label={t("kisi.acilDurumKisi2")}>
                                            <Input placeholder={t("kisi.acilDurumKisiHint")} />
                                        </Form.Item>
                                        <Form.Item name="acilDurumYakinlik2" label={t("kisi.acilDurumYakinlik")}>
                                            <Input placeholder={t("kisi.acilDurumYakinlikHint")} />
                                        </Form.Item>
                                        {(kisininYetkileri.includes("ADMIN") ||
                                            kisininYetkileri.includes("KISI") ||
                                            kisininYetkileri.includes("KISIKD")) && (
                                            <ConfigProvider locale={phoneLocale("trTR")}>
                                                <Form.Item name="acilDurumTelefon2" label={t("kisi.telefonNo")}>
                                                    <PhoneInput
                                                        defaultCountry={"TR"}
                                                        placeholder={t("kisi.telefonNoHint")}
                                                        enableSearch={true}
                                                        disabled={formPasif}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider>
                                        )}
                                    </>
                                ),
                            },
                            {
                                key: "3",
                                label: t("kisi.tabOzluk"),
                                children: (
                                    <>
                                        <Form.Item name="meslekiYeterlilikKodu" label={t("kisi.meslekiYeterlilikKodu")}>
                                            <Cascader options={meslekiYeterlilik} placeholder={t("genel.seciniz")} />
                                        </Form.Item>
                                        <SgkGorevKodu name="SGKgorevKodu" label={t("kisi.SGKgorevKodu")} />
                                        <BolumSelect mode="tags" name="sorumluluk" label={t("kisi.sorumluluk")} hint={t("kisi.sorumlulukHint")} />
                                        <Form.Item name="firmadakiUsername" label={t("kisi.firmadakiUsername")}>
                                            <Input placeholder={t("kisi.firmadakiUsernameHint")} />
                                        </Form.Item>
                                        <Form.Item name="firmadakiSicilno" label={t("kisi.firmadakiSicilno")}>
                                            <Input placeholder={t("kisi.firmadakiSicilnoHint")} />
                                        </Form.Item>
                                        <Form.Item name="dahiliNumara" label={t("kisi.dahiliNumara")}>
                                            <Input prefix={<PhoneOutlined />} placeholder={t("kisi.dahiliNumaraHint")} />
                                        </Form.Item>
                                        <Form.Item
                                            name="egitimDurumu"
                                            label={t("kisi.egitimDurumu")}
                                            requiredMark="optional"
                                            style={{ textAlign: "left" }}
                                        >
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option value="0">{t("kisi.egitimDurumu0")}</Select.Option>
                                                <Select.Option value="1">{t("kisi.egitimDurumu1")}</Select.Option>
                                                <Select.Option value="2">{t("kisi.egitimDurumu2")}</Select.Option>
                                                <Select.Option value="3">{t("kisi.egitimDurumu3")}</Select.Option>
                                                <Select.Option value="4">{t("kisi.egitimDurumu4")}</Select.Option>
                                                <Select.Option value="5">{t("kisi.egitimDurumu5")}</Select.Option>
                                                <Select.Option value="6">{t("kisi.egitimDurumu6")}</Select.Option>
                                                <Select.Option value="7">{t("kisi.egitimDurumu7")}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="okul" label={t("kisi.okul")}>
                                            <Input placeholder={t("kisi.okulHint")} />
                                        </Form.Item>
                                        <Form.Item name="girisTarihi" label={t("kisi.girisTarihi")}>
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                locale={locale}
                                                format={"YYYY/MM/DD"}
                                                placeholder={t("kisi.girisTarihi")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="cikisTarihi" label={t("kisi.cikisTarihi")}>
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                locale={locale}
                                                format={"YYYY/MM/DD"}
                                                placeholder={t("kisi.cikisTarihi")}
                                            />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            (kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("KISI") || kisininYetkileri.includes("KISIBG")) && {
                                key: "4",
                                label: t("kisi.tabBelge"),
                                children: <Belgeler sahip="KISI" id={id} />,
                            },
                            (kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("YETI")) && {
                                key: "5",
                                label: t("kisi.tabYetki"),
                                children: (
                                    <List
                                        style={{ margin: 20 }}
                                        header={
                                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => {
                                                        setYetkiProps({
                                                            kisiID: id,
                                                            firmaID: null,
                                                            roller: null,
                                                        });
                                                        setKisiFirmaModalOpen(true);
                                                    }}
                                                >
                                                    {t("kisi.firmaYetkiEkle")}
                                                </Button>
                                            </Space>
                                        }
                                        bordered
                                        dataSource={kisiFirmaDataSource}
                                        renderItem={(item) => (
                                            <List.Item key={item.firmaID}>
                                                <Space style={{ width: "100%" }}>
                                                    <Button
                                                        type="text"
                                                        icon={<EditOutlined />}
                                                        onClick={() => {
                                                            console.log("clicked : ", item);
                                                            setYetkiProps({
                                                                kisiID: item.kisiID,
                                                                firmaID: item.firmaID,
                                                                roller: item.roller,
                                                            });
                                                            setKisiFirmaModalOpen(true);
                                                        }}
                                                    />
                                                    {item.firmaismi}
                                                    {item.rolAdlari.map((item, idx) => {
                                                        return (
                                                            <Tag color="green" style={{ marginRight: 0 }}>
                                                                {item}
                                                            </Tag>
                                                        );
                                                    })}
                                                </Space>
                                            </List.Item>
                                        )}
                                    />
                                ),
                            },
                        ]}
                    />
                    {errMessage && (
                        <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                            <Alert type="error" message={t(errMessage)} />
                        </Space>
                    )}
                    <Modal
                        key="kisiFirmaYazForm"
                        open={kisiFirmaModalOpen}
                        onOk={() => {
                            setKisiFirmaModalOpen(false);
                        }}
                        onCancel={() => {
                            setKisiFirmaModalOpen(false);
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setKisiFirmaModalOpen(false);
                                }}
                                style={{ marginRight: 20 }}
                            >
                                {t("genel.vazgec")}
                            </Button>,
                            <Button
                                key="ok"
                                type="primary"
                                form="kisiFirmaYazForm"
                                htmlType="submit"
                                onClick={() => {
                                    setKisiFirmaModalOpen(false);
                                }}
                            >
                                {t("genel.kaydet")}
                            </Button>,
                        ]}
                    >
                        <KisiFirmaYaz fieldValues={yetkiProps} id={id} />
                    </Modal>
                </Form>
            </Content>
        </Layout>
    );
}

export default KisiGuncelle;
