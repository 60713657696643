import { Form, ConfigProvider, Select, Tooltip } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";

function KkdListesi(props) {
    const { t } = useTranslation();

    return (
        <ConfigProvider theme={{ components: { Select: { multipleItemHeight: 50 } } }}>
            <Form.Item name={props.name} label={props.label}>
                <Select placeholder={t("genel.seciniz")} mode="tags" style={{ height: 60, padding: 0, margin: 0 }}>
                    <Select.Option value="Ayk">
                        <Tooltip title={t("kkd.Ayk")}>
                            <img src="https://pro.kmysistem.com/images/kkdAyk.png" style={{ width: 50 }} />
                        </Tooltip>
                    </Select.Option>
                    <Select.Option value="Bon">
                        <Tooltip title={t("kkd.Bon")}>
                            <img src="https://pro.kmysistem.com/images/kkdBon.png" style={{ width: 50 }} />
                        </Tooltip>
                    </Select.Option>
                    <Select.Option value="Brt">
                        <Tooltip title={t("kkd.Brt")}>
                            <img src="https://pro.kmysistem.com/images/kkdBrt.png" style={{ width: 50 }} />
                        </Tooltip>
                    </Select.Option>
                    <Select.Option value="Eld">
                        <Tooltip title={t("kkd.Eld")}>
                            <img src="https://pro.kmysistem.com/images/kkdEld.png" style={{ width: 50 }} />
                        </Tooltip>
                    </Select.Option>
                    <Select.Option value="Gal">
                        <Tooltip title={t("kkd.Gal")}>
                            <img src="https://pro.kmysistem.com/images/kkdGal.png" style={{ width: 50 }} />
                        </Tooltip>
                    </Select.Option>
                    <Select.Option value="Goz">
                        <Tooltip title={t("kkd.Goz")}>
                            <img src="https://pro.kmysistem.com/images/kkdGoz.png" style={{ width: 50 }} />
                        </Tooltip>
                    </Select.Option>
                    <Select.Option value="Sol">
                        <Tooltip title={t("kkd.Sol")}>
                            <img src="https://pro.kmysistem.com/images/kkdSol.png" style={{ width: 50 }} />
                        </Tooltip>
                    </Select.Option>
                    <Select.Option value="Toz">
                        <Tooltip title={t("kkd.Toz")}>
                            <img src="https://pro.kmysistem.com/images/kkdToz.png" style={{ width: 50 }} />
                        </Tooltip>
                    </Select.Option>
                    <Select.Option value="Tum">
                        <Tooltip title={t("kkd.Tum")}>
                            <img src="https://pro.kmysistem.com/images/kkdTum.png" style={{ width: 50 }} />
                        </Tooltip>
                    </Select.Option>
                    <Select.Option value="Yuz">
                        <Tooltip title={t("kkd.Yuz")}>
                            <img src="https://pro.kmysistem.com/images/kkdYuz.png" style={{ width: 50 }} />
                        </Tooltip>
                    </Select.Option>
                </Select>
            </Form.Item>
        </ConfigProvider>
    );
}

export default KkdListesi;
