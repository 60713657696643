import { Form, Button, DatePicker, Drawer, Input, Layout, List, notification, Popconfirm, Space, Spin, Select, Tooltip, Typography } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { ClockCircleFilled, DislikeFilled, LikeFilled, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useBildirimiGetir, useBildirimGuncelle, useBildirimSil } from "../hooks/useBildirim";
import { useKisininYetkileriniGetir } from "../hooks/useKisi";
import { useAksiyonListele } from "../hooks/useAksiyon";
import SahaDegerleri from "../components/SahaDegerleri";
import AksiyonGuncelle from "./AksiyonGuncelle";
import KisiSelect from "../components/KisiSelect";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import BolumSelect from "../components/BolumSelect";

const { Content } = Layout;
const { TextArea } = Input;

function BildirimGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [api, contextHolder] = notification.useNotification();
    const { firmaID } = useGetUserInfo();

    const { kisininYetkileri } = useKisininYetkileriniGetir();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [aksiyonFormOpen, setAksiyonFormOpen] = useState(false);
    const [aksiyonID, setAksiyonID] = useState();
    const [durumu, setDurumu] = useState();
    const [kapaniyor, setKapaniyor] = useState(false);

    const { aksiyonlar } = useAksiyonListele("bildirim", id);
    const [bildirim, setBildirim] = useState();
    const { bildirimGuncelle } = useBildirimGuncelle(id);
    const { bildirimSil } = useBildirimSil(id);
    const [aksiyonListesi, setAksiyonListesi] = useState([]);
    const [formPasif, setFormPasif] = useState();

    useEffect(() => {
        setFormPasif(true);
        if (kisininYetkileri.includes("ADMIN")) setFormPasif(false);
        if (kisininYetkileri.includes("BILDKY")) setFormPasif(false);
        if (kisininYetkileri.includes("BILD")) setFormPasif(false);
    }, [kisininYetkileri]);

    const bildirimOnaylaButon = (kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("BILD") || kisininYetkileri.includes("BILDKY")) && (
        <Button
            key="bildirimOnaylaButon"
            type="primary"
            htmlType="submit"
            loading={isPending}
            onClick={() => {
                setDurumu("E");
            }}
        >
            {t("genel.onayla")}
        </Button>
    );

    const bildirimReddetButon = (kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("BILD") || kisininYetkileri.includes("BILDKY")) && (
        <Button
            key="bildirimReddetButon"
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger
            onClick={() => {
                setDurumu("H");
            }}
        >
            {t("genel.reddet")}
        </Button>
    );

    const bildirimKaydetButon = (kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("BILD") || kisininYetkileri.includes("BILDKY")) && (
        <Button key="bildirimKaydetButon" type="primary" htmlType="submit" loading={isPending}>
            {t("genel.kaydet")}
        </Button>
    );

    const bildirimSilButon = (kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("BILD") || kisininYetkileri.includes("BILDKS")) && (
        <Popconfirm
            key="bildirimSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                bildirimSil(id);
                navigate("/bildirimList");
            }}
            style={{ width: 400 }}
        >
            <Button key="bildirimSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        bildirimiGetir();
        setAksiyonListesi(aksiyonlar);
    }, [aksiyonlar]);

    const bildirimiGetir = () => {
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
        fetch(`http://89.19.24.18:3001/Bildirim/byId/${id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setBildirim(data);
            });
    };

    useEffect(() => {
        if (bildirim) {
            form.setFieldsValue({
                aciklama: bildirim.aciklama,
                bildirenID: bildirim.bildirenID,
                bolumID: bildirim.bolumID,
                konumID: bildirim.konumID,
                cozumOnerisi: bildirim.cozumOnerisi,
                bildirimZamani: bildirim.bildirimZamani ? dayjs(bildirim.bildirimZamani) : null,
                tur: bildirim.tur,
                durumu: bildirim.durumu,
                retNedeni: bildirim.retNedeni,
            });
            setDurumu(bildirim.durumu);
            setLoading(false);
        }
    }, [bildirim]);

    const onSubmit = (e) => {
        setIsPending(true);
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
        e.durumu = durumu;
        e.bildirimID = id;
        if (kapaniyor) e.kapanisZamani = Date.now();
        fetch(`http://89.19.24.18:3001/Bildirim/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
                bildirimiGetir();
                setIsPending(false);
            });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={formPasif}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="bildirimGuncelleKmyHeader"
                        caller="sayfaBaslik.bildirim"
                        buttons={[
                            bildirim.durumu === "Y" && bildirimOnaylaButon,
                            bildirim.durumu === "Y" && bildirimReddetButon,
                            bildirim.durumu === "Y" && bildirimKaydetButon,
                            bildirim.durumu === "E" && bildirimSilButon,
                        ]}
                        backURL="/bildirimList"
                    />
                    {contextHolder}
                    <KisiSelect name="bildirenID" label={t("bildirim.bildiren")} firmaID={firmaID} disabled={bildirim.durumu != "Y"} />
                    <Form.Item name="bildirimZamani" label={t("bildirim.bildirimZamani")}>
                        <DatePicker showTime disabled />
                    </Form.Item>
                    <BolumSelect name="bolumID" label={t("bildirim.bolum")} disabled={bildirim.durumu != "Y"} />
                    <SahaDegerleri name="konumID" id="bildirim.konum" label={t("bildirim.konum")} disabled={bildirim.durumu != "Y"} />

                    <Form.Item name="aciklama" label={t("bildirim.aciklama")}>
                        <TextArea placeholder={t("bildirim.aciklamaHint")} autoSize={true} disabled={bildirim.durumu != "Y"} />
                    </Form.Item>
                    <Form.Item name="cozumOnerisi" label={t("bildirim.cozumOnerisi")}>
                        <TextArea placeholder={t("bildirim.cozumOnerisi")} autoSize={true} disabled={bildirim.durumu != "Y"} />
                    </Form.Item>
                    {bildirim.durumu === "Y" && (
                        <Space direction="horizontal" style={{ width: "100%", marginBottom: 20, justifyContent: "center" }}>
                            <Typography.Text
                                style={{
                                    whiteSpace: "pre-line",
                                    alignItems: "center",
                                    color: "darkGray",
                                    textAlign: "center",
                                    display: "table-cell",
                                }}
                            >
                                Bu yeni bir bildirim.
                                <br />
                                Eğer işleme alınacaksa bildirim türünü seçiniz ve <b>onayla</b> butonuna basınız.
                                <br />
                                Aksi takdirde ret nedenini giriniz ve <b>reddet</b> butonuna basınız.
                            </Typography.Text>
                        </Space>
                    )}
                    <SahaDegerleri
                        name="tur"
                        id="bildirim.tur"
                        label={t("bildirim.tur")}
                        hint={t("bildirim.turHint")}
                        disabled={bildirim.durumu != "Y"}
                    />
                    {bildirim.durumu != "E" && (
                        <Form.Item name="retNedeni" label={t("bildirim.retNedeni")}>
                            <Input placeholder={t("bildirim.retNedeniHint")} disabled={bildirim.durumu != "Y"} />
                        </Form.Item>
                    )}
                    {bildirim.durumu != "Y" && (
                        <Form.Item name="kapanisNotu" label={t("bildirim.kapanisNotu")}>
                            <Input
                                placeholder={t("bildirim.kapanisNotuHint")}
                                disabled={bildirim.durumu === "K"}
                                suffix={
                                    bildirim.durumu != "K" && (
                                        <Button
                                            key="bildirimKapatButon"
                                            type="primary"
                                            htmlType="submit"
                                            style={{ backgroundColor: "green", margin: -12 }}
                                            onClick={() => {
                                                setKapaniyor(true);
                                                setDurumu("K");
                                            }}
                                        >
                                            {t("genel.kapat")}
                                        </Button>
                                    )
                                }
                            />
                        </Form.Item>
                    )}
                </Form>

                {bildirim.durumu === "E" && (
                    <List
                        style={{ margin: 20 }}
                        header={
                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                <Button type="primary" onClick={() => setAksiyonFormOpen(true)} icon={<PlusOutlined />}>
                                    Aksiyon Ekle
                                </Button>
                            </Space>
                        }
                        bordered
                        dataSource={aksiyonListesi}
                        renderItem={(item) => (
                            <List.Item key={item.aksiyonID}>
                                <Space style={{ width: "100%", justifyContent: "space-between" }}>
                                    <Space>
                                        <Button
                                            type="text"
                                            icon={<EditOutlined style={{ fontSize: 20 }} />}
                                            onClick={() => {
                                                setAksiyonID(item.aksiyonID);
                                                setAksiyonFormOpen(true);
                                            }}
                                        />
                                        {item.aciklama}
                                    </Space>
                                    <Tooltip title={t(`aksiyon.${item.aksiyonDurumu}`)}>
                                        {item.aksiyonDurumu === "Y" ? (
                                            <ClockCircleFilled style={{ color: "red", fontSize: 20 }} />
                                        ) : item.aksiyonDurumu === "D" ? (
                                            <DislikeFilled style={{ color: "orange", fontSize: 20 }} />
                                        ) : (
                                            <LikeFilled style={{ color: "green", fontSize: 20 }} />
                                        )}
                                    </Tooltip>
                                </Space>
                            </List.Item>
                        )}
                    />
                )}
                <Drawer
                    title="Aksiyon"
                    width={600}
                    onClose={() => {
                        setAksiyonFormOpen(false);
                    }}
                    open={aksiyonFormOpen}
                    extra={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setAksiyonFormOpen(false);
                            }}
                            style={{ marginRight: 10 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                    ]}
                >
                    {aksiyonFormOpen ? <AksiyonGuncelle aksiyonID={aksiyonID} acKapa={aksiyonFormOpen} ilgiliTipi="bildirim" ilgiliID={id} /> : null}
                </Drawer>
            </Content>
        </Layout>
    );
}

export default BildirimGuncelle;
