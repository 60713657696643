import { Form, Checkbox, Col, Row, Space, Tooltip } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";

function RolSatiri(props) {
    const { t } = useTranslation();

    return (
        <Row style={{ paddingTop: 8, marginBottom: -16, borderTop: "1px solid #888" }}>
            <Col span={9} align="right" style={{ paddingRight: 10 }}>
                {t(`yetkiler.${props.yetkiKodu}`)}
            </Col>
            <Col span={1} align="middle">
                <Tooltip title={t(`yetkiler.${props.yetkiKodu}XX`)}>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Form.Item name={`${props.yetkiKodu}`} valuePropName="checked">
                            <Checkbox disabled={props.yetkiAdmin} />
                        </Form.Item>
                    </Space>
                </Tooltip>
            </Col>
            <Col span={1} align="middle">
                <Tooltip title={t(`yetkiler.${props.yetkiKodu}KG`)}>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Form.Item name={`${props.yetkiKodu}KG`} valuePropName="checked">
                            <Checkbox disabled={props.yetkiAdmin || props.yetkiXX} />
                        </Form.Item>
                    </Space>
                </Tooltip>
            </Col>
            <Col span={1} align="middle">
                <Tooltip title={t(`yetkiler.${props.yetkiKodu}KD`)}>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Form.Item name={`${props.yetkiKodu}KD`} valuePropName="checked">
                            <Checkbox disabled={props.yetkiAdmin || props.yetkiXX} />
                        </Form.Item>
                    </Space>
                </Tooltip>
            </Col>
            <Col span={1} align="middle">
                <Tooltip title={t(`yetkiler.${props.yetkiKodu}KE`)}>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Form.Item name={`${props.yetkiKodu}KE`} valuePropName="checked">
                            <Checkbox disabled={props.yetkiAdmin || props.yetkiXX} />
                        </Form.Item>
                    </Space>
                </Tooltip>
            </Col>
            <Col span={1} align="middle">
                <Tooltip title={t(`yetkiler.${props.yetkiKodu}KS`)}>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Form.Item name={`${props.yetkiKodu}KS`} valuePropName="checked">
                            <Checkbox disabled={props.yetkiAdmin || props.yetkiXX} />
                        </Form.Item>
                    </Space>
                </Tooltip>
            </Col>
            <Col span={1} align="middle">
                <Tooltip title={t(`yetkiler.${props.yetkiKodu}BG`)}>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Form.Item name={`${props.yetkiKodu}BG`} valuePropName="checked">
                            <Checkbox disabled={props.yetkiAdmin || props.yetkiXX} />
                        </Form.Item>
                    </Space>
                </Tooltip>
            </Col>
            <Col span={1} align="middle">
                <Tooltip title={t(`yetkiler.${props.yetkiKodu}BD`)}>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Form.Item name={`${props.yetkiKodu}BY`} valuePropName="checked">
                            <Checkbox disabled={props.yetkiAdmin || props.yetkiXX} />
                        </Form.Item>
                    </Space>
                </Tooltip>
            </Col>
            <Col span={1} align="middle">
                <Tooltip title={t(`yetkiler.${props.yetkiKodu}BE`)}>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Form.Item name={`${props.yetkiKodu}BE`} valuePropName="checked">
                            <Checkbox disabled={props.yetkiAdmin || props.yetkiXX} />
                        </Form.Item>
                    </Space>
                </Tooltip>
            </Col>
            <Col span={1} align="middle">
                <Tooltip title={t(`yetkiler.${props.yetkiKodu}BS`)}>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Form.Item name={`${props.yetkiKodu}BS`} valuePropName="checked">
                            <Checkbox disabled={props.yetkiAdmin || props.yetkiXX} />
                        </Form.Item>
                    </Space>
                </Tooltip>
            </Col>
        </Row>
    );
}

export default RolSatiri;
