import { useGetToken } from "./useGetToken";
import { useEffect, useState } from "react";

export const useSdsKaydet = () => {
    const { accessToken } = useGetToken();
    const sdsKaydet = async (e) => {
        console.log("kaydedilecek : ", e);
        fetch(`http://89.19.24.18:3001/Sds/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                return data.id;
                console.log("SDS kayıt cevabı : ", data.id);
            });
    };

    return { sdsKaydet };
};

export const useSdsSil = (id) => {
    const { accessToken } = useGetToken();
    const sdsSil = async () => {
        fetch(`http://89.19.24.18:3001/Sds/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { sdsSil };
};

export const useSdsListele = () => {
    const { accessToken } = useGetToken();
    const [sdsListesi, setSdsListesi] = useState([]);
    const sdsListele = async () => {
        fetch("http://89.19.24.18:3001/Sds", {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setSdsListesi(data);
            });

        return () => sdsListesi;
    };
    useEffect(() => {
        sdsListele();
    }, []);

    return { sdsListesi };
};

export const useSdsGetir = (id) => {
    const [sds, setSds] = useState([]);
    const { accessToken } = useGetToken();
    const sdsGetir = async () => {
        fetch(`http://89.19.24.18:3001/Sds/${id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setSds(data);
            });

        return () => sds;
    };

    useEffect(() => {
        sdsGetir();
    }, []);

    return { sds };
};
