import ulkeililce from "../constant/irsaliye.xml";
import React, { useEffect, useState } from "react";
//import { XMLParser } from "react-xml-parser";

function Irsaliye() {
    const [item, setItem] = useState();

    useEffect(() => {
        /*
        fetch("../constant/irsaliye.xml")
            .then((res) => {
                res.text();
            })
            .then((data) => {
                var xml = new XMLParser.parseFromString(data);
                console.log(xml);
            })
            .catch((err) => console.log(err));
            */
    }, []);

    useEffect(() => {
        console.log("item :", item);
    }, [item]);
}

export default Irsaliye;
