import { Form, Button, DatePicker, Input, message, notification, Popconfirm, Select, Space, Spin, Switch, Upload, Tooltip } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMerdivenGetir, useMerdivenGuncelle, useMerdivenSil } from "../hooks/useMerdiven";
import KmyHeader from "./KmyHeader";
import "react-quill/dist/quill.snow.css";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";

const { TextArea } = Input;

function MerdivenGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [merdivenID, setMerdivenID] = useState();

    const { merdiven } = useMerdivenGetir(id);
    const { merdivenGuncelle } = useMerdivenGuncelle(id);
    const { merdivenSil } = useMerdivenSil(id);

    const merdivenKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button type="primary" htmlType="submit" loading={isPending} danger={errMessage} key="chapterSaveButton">
            {t("genel.kaydet")}
        </Button>
    );

    const merdivenSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="merdivenSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                merdivenSil(id);
                navigate(`/merdivenGuncelle/${merdivenID}`);
            }}
            style={{ width: 400 }}
        >
            <Button type="primary" danger key="merdivenSilButon">
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (merdiven) {
            form.setFieldsValue({
                demirbasNo: merdiven.demirbasNo,
                aciklama: merdiven.aciklama,
                uretici: merdiven.uretici,
                uretimTarihi: merdiven.uretimTarihi ? dayjs(merdiven.uretimTarihi) : null,
                yukseklik: merdiven.yukseklik,
                genislik: merdiven.genislik,
                basamakSayisi: merdiven.basamakSayisi,
                kontrolPeriyodu: merdiven.kontrolPeriyodu,
                planlananKontrol: merdiven.planlananKontrol ? dayjs(merdiven.planlananKontrol) : null,
            });
            setMerdivenID(merdiven.merdivenID);
            setLoading(false);
            console.log("merdivenID : ", merdiven.problemliMi);
        }
    }, [merdiven]);

    const onSubmit = (e) => {
        setIsPending(true);
        merdivenGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Form
            form={form}
            disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
            autoComplete="off"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 14 }}
            onFinish={(values) => {
                onSubmit(values);
            }}
            onFinishFailed={(error) => {
                console.log("onFinishFailed : ", error);
            }}
        >
            <KmyHeader caller="sayfaBaslik.merdivenGuncelle" buttons={[merdivenKaydetButon, merdivenSilButon]} backURL="/merdivenList" />
            {contextHolder}
            <Form.Item name="demirbasNo" label={t("merdiven.demirbasNo")} style={{ paddingTop: 20 }}>
                <Input placeholder={t("merdiven.demirbasNoHint")} addonAfter={merdiven ? merdiven.merdivenID : null} />
            </Form.Item>
            <Form.Item name="aciklama" label={t("merdiven.aciklama")}>
                <Input placeholder={t("merdiven.aciklamaHint")} />
            </Form.Item>
            <Form.Item name="uretici" label={t("merdiven.uretici")}>
                <Input placeholder={t("merdiven.ureticiHint")} />
            </Form.Item>
            <Form.Item name="uretimTarihi" label={t("merdiven.uretimTarihi")}>
                <DatePicker style={{ width: "100%" }} locale={locale} format={"YYYY/MM/DD"} placeholder={t("merdiven.uretimTarihiHint")} />
            </Form.Item>
            <Form.Item name="yukseklik" label={t("merdiven.yukseklik")}>
                <Input placeholder={t("merdiven.yukseklikHint")} addonAfter={t("genel.cm")} />
            </Form.Item>
            <Form.Item name="genislik" label={t("merdiven.genislik")}>
                <Input placeholder={t("merdiven.genislikHint")} addonAfter={t("genel.cm")} />
            </Form.Item>
            <Form.Item name="basamakSayisi" label={t("merdiven.basamakSayisi")}>
                <Input placeholder={t("merdiven.basamakSayisiHint")} />
            </Form.Item>
            <Form.Item name="kontrolPeriyodu" label={t("merdiven.kontrolPeriyodu")}>
                <Input placeholder={t("merdiven.kontrolPeriyoduHint")} addonAfter={t("genel.ay")} />
            </Form.Item>
            <Form.Item name="planlananKontrol" label={t("merdiven.planlananKontrol")}>
                <DatePicker style={{ width: "100%" }} locale={locale} format={"YYYY/MM/DD"} placeholder={t("merdiven.planlananKontrolHint")} />
            </Form.Item>
        </Form>
    );
}

export default MerdivenGuncelle;
