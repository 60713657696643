import { React } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import { Avatar, Tooltip } from "antd";

function FirmaLogosu() {
    const navigate = useNavigate();
    const { firmaismi, firmaLogo } = useGetUserInfo();

    return (
        <Tooltip key="firmaismiToolkit" title={firmaismi}>
            <Avatar
                key="companyAvatar"
                src={firmaLogo && `${firmaLogo}`}
                alt={firmaismi}
                shape="square"
                style={{ height: 40, width: 120, borderRadius: 0, cursor: "pointer" }}
                referrerPolicy="no-referrer"
                onClick={() => {
                    navigate("/firmaSec");
                }}
            ></Avatar>
        </Tooltip>
    );
}

export default FirmaLogosu;
