import { Form, Button, Input, notification, Select, Space } from "antd";
import { React, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBolumEkle } from "../hooks/useBolum";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import { useKisininYetkileriniGetir } from "../hooks/useKisi";

function BolumSelect(props) {
    const { t } = useTranslation();
    const { bolumEkle } = useBolumEkle();
    const [bolumler, setBolumler] = useState([]);
    const [bolumOptions, setBolumOptions] = useState([]);
    const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
    const { firmaID } = useGetUserInfo();

    const { kisininYetkileri } = useKisininYetkileriniGetir();
    const [bolumIsmi, setBolumIsmi] = useState("");

    const bolumleriGetir = async () => {
        let unsubscribe;
        fetch("http://89.19.24.18:3001/Bolum", { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                unsubscribe = data;
                setBolumler(data);
            });
        setBolumler(unsubscribe);
    };

    useEffect(() => {
        if (bolumler) {
            const opts = bolumler.map((item) => ({ value: String(item.id), label: `${item.bolumIsmi}` }));
            setBolumOptions(opts);
        }
    }, [bolumler]);

    useEffect(() => {
        bolumleriGetir();
    }, []);

    const bolumRef = useRef(null);

    return (
        <Form.Item name={props.name} label={props.label}>
            <Select
                placeholder={props.hint}
                mode={props.mode}
                disabled={props.disabled}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        {(kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("BOLU") || kisininYetkileri.includes("BOLUKE")) && (
                            <Space.Compact style={{ width: "100%" }}>
                                <Input
                                    placeholder={t("bolum.eklenecekBolumHint")}
                                    ref={bolumRef}
                                    value={bolumIsmi}
                                    onChange={(e) => {
                                        setBolumIsmi(e.target.value);
                                    }}
                                    onKeyDown={(e) => e.stopPropagation()}
                                />
                                <Button
                                    disabled={bolumIsmi == ""}
                                    type="primary"
                                    onClick={() => {
                                        bolumEkle({ bolumIsmi: bolumIsmi });
                                        fetch(`http://89.19.24.18:3001/Bolum/`, {
                                            method: "PUT",
                                            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
                                            body: JSON.stringify({
                                                bolumIsmi: bolumIsmi,
                                                firmaID: firmaID,
                                            }),
                                        })
                                            .then(function (response) {
                                                return response.json();
                                            })
                                            .then(function (data) {
                                                bolumleriGetir();
                                            });
                                    }}
                                >
                                    {t("bolum.bolumEkle")}
                                </Button>
                            </Space.Compact>
                        )}
                    </>
                )}
            >
                {bolumOptions.map((item, index) =>
                    bolumIsmi === null || item.label.toLowerCase().includes(bolumIsmi.toLowerCase()) ? (
                        <Select.Option value={item.value} key={index}>
                            {item.label}
                        </Select.Option>
                    ) : null
                )}
            </Select>
        </Form.Item>
    );
}

export default BolumSelect;
