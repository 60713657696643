import { Button, Form, Divider, Input, Layout, Modal, notification, Popconfirm, Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSdsEkle, useSdsGetir, useSdsKaydet, useSdsSil } from "../hooks/useSds";
import { useKisininYetkileriniGetir } from "../hooks/useKisi";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import KmyHeader from "./KmyHeader";
import AdrListesi from "../components/AdrListesi";
import KkdListesi from "../components/KkdListesi";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";

const { Content } = Layout;
const { TextArea } = Input;

function Sds() {
    let { id } = useParams();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { kisininYetkileri } = useKisininYetkileriniGetir();
    const [api, contextHolder] = notification.useNotification();

    const [loading, setLoading] = useState(true);
    const [versiyonModalOpen, setVersiyonModalOpen] = useState(false);
    const [durum, setDurum] = useState();
    const [taslakSahibi, setTaslakSahibi] = useState();
    const [sonrakiID, setSonrakiID] = useState();
    const [sdsID, setSdsID] = useState();

    const { kisiID } = useGetUserInfo();
    const { sds } = useSdsGetir(id);
    const { sdsSil } = useSdsSil(id);
    const { sdsKaydet } = useSdsKaydet();
    const [kayitDegisebilir, setKayitDegisebilir] = useState();
    const [kisiYazabilir, setKisiYazabilir] = useState();
    const [kisiSilebilir, setKisiSilebilir] = useState();
    const [versiyonArttir, setVersiyonArttir] = useState(false);
    const [versiyonYayinla, setVersiyonYayinla] = useState(false);

    const versiyonYayinlaClick = () => {
        setLoading(true);
        console.log("versiyonYayinlaClick");
        sdsKaydet({
            sdsID: sdsID,
            kayitID: id,
            durum: "S",
            versiyonKodu: form.getFieldValue("versiyonKodu"),
            versiyonDetay: form.getFieldValue("versiyonDetay"),
        }).then(() => {
            navigate("/sdsList");
        });
        setLoading(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    const yeniVersiyonOlusturClick = () => {
        setLoading(true);
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"];
        console.log("accessToken : ", accessToken);
        // mevcut kaydın bir kopyası oluşturuluyor
        fetch(`http://89.19.24.18:3001/Sds/klon`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({ kayitID: id }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                id = data.id;
                setDurum("T");
                setVersiyonArttir(false);
                setVersiyonYayinla(false);
                navigate(`/sds/${data.id}`);
            });

        setLoading(false);
    };

    const sdsKaydetButon =
        kayitDegisebilir && kisiYazabilir ? (
            <Button key="acilDurumPlaniKaydetButon" type="primary" htmlType="submit" loading={loading}>
                {t("genel.kaydet")}
            </Button>
        ) : null;

    const sdsSilButon = kisiSilebilir && kayitDegisebilir && (
        <Popconfirm
            key="sdsSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                sdsSil(id);
                navigate("/sdsList");
            }}
            style={{ width: 400 }}
        >
            <Button key="sdsSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    const versiyonArttirButon = versiyonArttir && (
        <Button key="sdsVersiyonArttirButon" type="primary" disabled={false} onClick={yeniVersiyonOlusturClick}>
            {"Yeni Versiyon Oluştur"}
        </Button>
    );

    const versiyonYayinlaButon = versiyonYayinla && (
        <Button
            key="sdsVersiyonYayinlaButon"
            type="primary"
            disabled={false}
            onClick={() => {
                setVersiyonModalOpen(true);
            }}
        >
            {"Versiyon Yayınla"}
        </Button>
    );

    useEffect(() => {
        if (sds) {
            form.setFieldsValue({
                maddeKodu: sds.maddeKodu,
                maddeAdi: sds.maddeAdi,
                sdsTarihi: sds.sdsTarihi ? dayjs(sds.sdsTarihi) : null,
                versiyon: sds.versiyon,
                dili: sds.dili,
                reach: sds.reach,
                cas: sds.cas,
                ec: sds.ec,
                icsc: sds.icsc,
                tanimlananKullanim: sds.tanimlananKullanim,
                tavsiyeEdilmeyenKullanim: sds.tavsiyeEdilmeyenKullanim,
                ureticiFirma: sds.ureticiFirma,
                ureticidekiKodu: sds.ureticidekiKodu,
                ureticidekiAdi: sds.ureticidekiAdi,
                acilDurumTelefonNo: sds.acilDurumTelefonNo,
                hKodlari: sds.hKodlari,
                pKodlari: sds.pKodlari,
                etiketUnsurlari: sds.etiketUnsurlari,
                uyeriTR: sds.uyeriTR,
                uyariEN: sds.uyariEN,
                digerZararlar: sds.digerZararlar,
                maddeler: sds.maddeler,
                karisimlar: sds.karisimlar,
                ilkYardimTavsiye: sds.ilkYardimTavsiye,
                soluma: sds.soluma,
                cildeTemas: sds.cildeTemas,
                gözeTemas: sds.gözeTemas,
                yutma: sds.yutma,
                ilkYardimOzelNot: sds.ilkYardimOzelNot,
                yanginRiskleri: sds.yanginRiskleri,
                sondurmeTavsiyeleri: sds.sondurmeTavsiyeleri,
                yanginEkOnlemler: sds.yanginEkOnlemler,
                yanginSonduruculer: sds.yanginSonduruculer,
                yasakYanginSonduruculer: sds.yasakYanginSonduruculer,
                dokulmeKisiselOnlemler: sds.dokulmeKisiselOnlemler,
                dokulmeCevreselOnlemler: sds.dokulmeCevreselOnlemler,
                dokulmeYontemMateryal: sds.dokulmeYontemMateryal,
                dokulmeKkd: sds.dokulmeKkd,
                ellecleme: sds.ellecleme,
                depolama: sds.depolama,
                depolamaSicakligi: sds.depolamaSicakligi,
                depolamaTedbirleri: sds.depolamaTedbirleri,
                depolamaTankAmbalaj: sds.depolamaTankAmbalaj,
                maruzKalmaLimitleri: sds.maruzKalmaLimitleri,
                solunumTehlikeGurubu: sds.solunumTehlikeGurubu,
                solunumMiktarGurubu: sds.solunumMiktarGurubu,
                solunumAcigaCikma: sds.solunumAcigaCikma,
                solunumKorumaKilavuzu: sds.solunumKorumaKilavuzu,
                ciltTehlikeGurubu: sds.ciltTehlikeGurubu,
                ciltEtkiYuzeyi: sds.ciltEtkiYuzeyi,
                ciltEtkiSuresi: sds.ciltEtkiSuresi,
                ciltKorumaKilavuzu: sds.ciltKorumaKilavuzu,
                ciltCevreKorumaKilavuzu: sds.ciltCevreKorumaKilavuzu,
                ciltUyariCumlesi: sds.ciltUyariCumlesi,
                muhendislikOnlemleri: sds.muhendislikOnlemleri,
                bireyselKorumaGoz: sds.bireyselKorumaGoz,
                bireyselKorumaCilt: sds.bireyselKorumaCilt,
                bireyselKorumaEl: sds.bireyselKorumaEl,
                bireyselKorumaSolunum: sds.bireyselKorumaSolunum,
                bireyselKorumaIsılZarar: sds.bireyselKorumaIsılZarar,
                bireyselKorumaHijyen: sds.bireyselKorumaHijyen,
                bireyselKorumaDiger: sds.bireyselKorumaDiger,
                bireyselKorumaEkBilgi: sds.bireyselKorumaEkBilgi,
                cevreselMaruziyetKontrolleri: sds.cevreselMaruziyetKontrolleri,
                maddeHali: sds.maddeHali,
                gorunumForm: sds.gorunumForm,
                renk: sds.renk,
                koku: sds.koku,
                kokuEsigi: sds.kokuEsigi,
                yogunluk: sds.yogunluk,
                ph: sds.ph,
                erimeNoktasi: sds.erimeNoktasi,
                donmaNoktasi: sds.donmaNoktasi,
                kaynamaNoktasi: sds.kaynamaNoktasi,
                kaynamaAraligi: sds.kaynamaAraligi,
                parlamaNoktasi: sds.parlamaNoktasi,
                buharlasmaHizi: sds.buharlasmaHizi,
                altAlevlenirlik: sds.altAlevlenirlik,
                ustAlevlenirlik: sds.ustAlevlenirlik,
                patlayiciLimitleri: sds.patlayiciLimitleri,
                kendiligindenTutusma: sds.kendiligindenTutusma,
                buharBasinci: sds.buharBasinci,
                buharYogunlugu: sds.buharYogunlugu,
                cozunurluk: sds.cozunurluk,
                dagilimKatsayisi: sds.dagilimKatsayisi,
                bozunmaSicakligi: sds.bozunmaSicakligi,
                akiskanlik: sds.akiskanlik,
                patlayicilik: sds.patlayicilik,
                oksitleyicilik: sds.oksitleyicilik,
                dinamikViskozite: sds.dinamikViskozite,
                kinematikViskozite: sds.kinematikViskozite,
                ozellikEkBilgi: sds.ozellikEkBilgi,
                reaksiyonOlasiligi: sds.reaksiyonOlasiligi,
                uyumsuzMalzemeler: sds.uyumsuzMalzemeler,
                kacinilmasiGereken: sds.kacinilmasiGereken,
                tehlikeliBilesenler: sds.tehlikeliBilesenler,
                toksikolojikBilgiler: sds.toksikolojikBilgiler,
                ekolojikBilgiler: sds.ekolojikBilgiler,
                zararliBozunmaUrunleri: sds.zararliBozunmaUrunleri,
                urunBertaraf: sds.urunBertaraf,
                ambalajBertaraf: sds.ambalajBertaraf,
                atikKodu: sds.atikKodu,
                tasimacilikBilgileri: sds.tasimacilikBilgileri,
                mevzuatBilgileri: sds.mevzuatBilgileri,
            });
            setVersiyonArttir(false);
            setVersiyonYayinla(false);
            setSdsID(sds.sdsID);
            setDurum(sds.durum);
            setSonrakiID(sds.sonrakiID);
            setTaslakSahibi(sds.taslakSahibi);
            setLoading(false);
        }
    }, [sds]);

    useEffect(() => {
        if (kisininYetkileri.includes("ADMIN")) {
            setKisiYazabilir(true);
            setKisiSilebilir(true);
        }
        if (kisininYetkileri.includes("ADUP")) {
            setKisiYazabilir(true);
            setKisiSilebilir(true);
        }
        if (kisininYetkileri.includes("ADUPKD")) {
            setKisiYazabilir(true);
        }
        if (kisininYetkileri.includes("ADUPKS")) {
            setKisiSilebilir(true);
        }

        if (!id) {
            setKayitDegisebilir(true);
            setKisiSilebilir(false);
        } else if (durum == "T") {
            //taslak durumundaysa
            if (taslakSahibi == kisiID) {
                // ve taslak sahibi bu kişiyse
                setKayitDegisebilir(true);
                setVersiyonYayinla(true);
            }
        } else if (durum == "S") {
            if (sonrakiID == null) {
                setVersiyonArttir(true);
            }
        }
    }, [kisininYetkileri, durum, taslakSahibi, sonrakiID, id, versiyonArttir, versiyonYayinla]);

    const onSubmit = (e) => {
        if (id) e.kayitID = id;
        console.log("submit", e);
        setLoading(true);
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"];
        console.log("accessToken : ", accessToken);
        fetch(`http://89.19.24.18:3001/Sds/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                navigate(`/sds/${data.id}`);
            });
        setLoading(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!(kisiYazabilir && kayitDegisebilir)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        console.log("onFinish : ", values);
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="sdsHeader"
                        caller="sayfaBaslik.sds"
                        backURL="/"
                        buttons={[versiyonArttirButon, versiyonYayinlaButon, sdsKaydetButon, sdsSilButon]}
                    />
                    <Tabs
                        tabPosition="left"
                        type="card"
                        defaultActiveKey="1"
                        items={[
                            {
                                key: "01",
                                label: t("sds.tab01"),
                                children: (
                                    <>
                                        <Divider orientation="left">1.1 Maddenin / Karışımın Kimliği</Divider>
                                        <Form.Item name="maddeKodu" label={t("sds.maddeKodu")}>
                                            <Input placeholder={t("sds.maddeKoduHint")} />
                                        </Form.Item>
                                        <Form.Item name="maddeAdi" label={t("sds.maddeAdi")}>
                                            <Input placeholder={t("sds.maddeAdiHint")} />
                                        </Form.Item>
                                        <Form.Item name="sdsTarihi" label={t("sds.sdsTarihi")}>
                                            <Input placeholder={t("sds.sdsTarihiHint")} />
                                        </Form.Item>
                                        <Form.Item name="versiyon" label={t("sds.versiyon")}>
                                            <Input placeholder={t("sds.versiyonHint")} />
                                        </Form.Item>
                                        <Form.Item name="dili" label={t("sds.dili")}>
                                            <Input placeholder={t("sds.diliHint")} />
                                        </Form.Item>
                                        <Form.Item name="reach" label={t("sds.reach")}>
                                            <Input placeholder={t("sds.reachHint")} />
                                        </Form.Item>
                                        <Form.Item name="cas" label={t("sds.cas")}>
                                            <Input placeholder={t("sds.casHint")} />
                                        </Form.Item>
                                        <Form.Item name="ec" label={t("sds.ec")}>
                                            <Input placeholder={t("sds.ecHint")} />
                                        </Form.Item>
                                        <Form.Item name="icsc" label={t("sds.icsc")}>
                                            <Input placeholder={t("sds.icscHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">
                                            1.2 Madde veya karışımın belirlenmiş kullanımları ve tavsiye edilmeyen kullanımları
                                        </Divider>
                                        <Form.Item name="tanimlananKullanim" label={t("sds.tanimlananKullanim")}>
                                            <Input placeholder={t("sds.tanimlananKullanimHint")} />
                                        </Form.Item>
                                        <Form.Item name="tavsiyeEdilmeyenKullanim" label={t("sds.tavsiyeEdilmeyenKullanim")}>
                                            <Input placeholder={t("sds.tavsiyeEdilmeyenKullanimHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">1.3 Güvenlik bilgi formu tedarikçisinin bilgileri</Divider>
                                        <Form.Item name="ureticiFirma" label={t("sds.ureticiFirma")}>
                                            <Input placeholder={t("sds.ureticiFirmaHint")} />
                                        </Form.Item>
                                        <Form.Item name="ureticidekiKodu" label={t("sds.ureticidekiKodu")}>
                                            <Input placeholder={t("sds.ureticidekiKoduHint")} />
                                        </Form.Item>
                                        <Form.Item name="ureticidekiAdi" label={t("sds.ureticidekiAdi")}>
                                            <Input placeholder={t("sds.ureticidekiAdiHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">1.4 Acil durum telefon numarası</Divider>
                                        <Form.Item name="acilDurumTelefonNo" label={t("sds.acilDurumTelefonNo")}>
                                            <Input placeholder={t("sds.acilDurumTelefonNoHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "02",
                                label: t("sds.tab02"),
                                children: (
                                    <>
                                        <Divider orientation="left">2.1 Madde ve karışımın sınıflandırılması</Divider>
                                        <Form.Item name="hKodlari" label={t("sds.hKodlari")}>
                                            <Input placeholder={t("sds.hKodlariHint")} />
                                        </Form.Item>
                                        <Form.Item name="pKodlari" label={t("sds.pKodlari")}>
                                            <Input placeholder={t("sds.pKodlariHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">2.2 Etiket unsurları</Divider>
                                        <AdrListesi name="etiketUnsurlari" label={t("sds.etiketUnsurlari")} />
                                        <Form.Item name="uyeriTR" label={t("sds.uyeriTR")}>
                                            <Input placeholder={t("sds.uyeriTRHint")} />
                                        </Form.Item>
                                        <Form.Item name="uyariEN" label={t("sds.uyariEN")}>
                                            <Input placeholder={t("sds.uyariENHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">2.3 Diğer zararlar</Divider>
                                        <Form.Item name="digerZararlar" label={t("sds.digerZararlar")}>
                                            <Input placeholder={t("sds.digerZararlarHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "03",
                                label: t("sds.tab03"),
                                children: (
                                    <>
                                        <Divider orientation="left">3.1 Maddeler</Divider>
                                        <Form.Item name="maddeler" label={t("sds.maddeler")}>
                                            <Input placeholder={t("sds.maddelerHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">3.2 Karışımlar</Divider>
                                        <Form.Item name="karisimlar" label={t("sds.karisimlar")}>
                                            <Input placeholder={t("sds.karisimlarHint")} />
                                        </Form.Item>
                                        <div>Ticari İsim </div>
                                        <div>Tedarikçi </div>
                                        <div>Maddenin Türü</div>
                                        <div>tek/çok bileşen, UVCB vb </div>
                                        <div>% </div>
                                        <div>Saflık % </div>
                                        <div>Gramaj </div>
                                        <div>Madde Hali </div>
                                        <div>CAS # </div>
                                        <div>EC # </div>
                                        <div>REACH # </div>
                                        <div>KKDİK Ön Kayıt </div>
                                        <div>KKDİK Kayıt No </div>
                                        <div>KKDİK Sınıf </div>
                                        <div>Uyarı Kelimesi</div>
                                        <div>Zararlılık sınıfı ve kategorisi </div>
                                        <div>GHS </div>
                                        <div>Zararlılık İfadeleri </div>
                                        <div>Önlem İfadeleri </div>
                                        <div>Notlar / M faktörü </div>
                                        <div>Harmonize checkbox</div>
                                        <div>Organik checkbox</div>
                                    </>
                                ),
                            },
                            {
                                key: "04",
                                label: t("sds.tab04"),
                                children: (
                                    <>
                                        <Form.Item name="ilkYardimTavsiye" label={t("sds.ilkYardimTavsiye")}>
                                            <Input placeholder={t("sds.ilkYardimTavsiyeHint")} />
                                        </Form.Item>
                                        <Form.Item name="soluma" label={t("sds.soluma")}>
                                            <Input placeholder={t("sds.solumaHint")} />
                                        </Form.Item>
                                        <Form.Item name="cildeTemas" label={t("sds.cildeTemas")}>
                                            <Input placeholder={t("sds.cildeTemasHint")} />
                                        </Form.Item>
                                        <Form.Item name="gözeTemas" label={t("sds.gözeTemas")}>
                                            <Input placeholder={t("sds.gözeTemasHint")} />
                                        </Form.Item>
                                        <Form.Item name="yutma" label={t("sds.yutma")}>
                                            <Input placeholder={t("sds.yutmaHint")} />
                                        </Form.Item>
                                        <Form.Item name="ilkYardimOzelNot" label={t("sds.ilkYardimOzelNot")}>
                                            <Input placeholder={t("sds.ilkYardimOzelNotHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "05",
                                label: t("sds.tab05"),
                                children: (
                                    <>
                                        <Form.Item name="yanginRiskleri" label={t("sds.yanginRiskleri")}>
                                            <Input placeholder={t("sds.yanginRiskleriHint")} />
                                        </Form.Item>
                                        <Form.Item name="sondurmeTavsiyeleri" label={t("sds.sondurmeTavsiyeleri")}>
                                            <Input placeholder={t("sds.sondurmeTavsiyeleriHint")} />
                                        </Form.Item>
                                        <Form.Item name="yanginEkOnlemler" label={t("sds.yanginEkOnlemler")}>
                                            <Input placeholder={t("sds.yanginEkOnlemlerHint")} />
                                        </Form.Item>
                                        <Form.Item name="yanginSonduruculer" label={t("sds.yanginSonduruculer")}>
                                            <Input placeholder={t("sds.yanginSonduruculerHint")} />
                                        </Form.Item>
                                        <Form.Item name="yasakYanginSonduruculer" label={t("sds.yasakYanginSonduruculer")}>
                                            <Input placeholder={t("sds.yasakYanginSonduruculerHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "06",
                                label: t("sds.tab06"),
                                children: (
                                    <>
                                        <Form.Item name="dokulmeKisiselOnlemler" label={t("sds.dokulmeKisiselOnlemler")}>
                                            <Input placeholder={t("sds.dokulmeKisiselOnlemlerHint")} />
                                        </Form.Item>
                                        <Form.Item name="dokulmeCevreselOnlemler" label={t("sds.dokulmeCevreselOnlemler")}>
                                            <Input placeholder={t("sds.dokulmeCevreselOnlemlerHint")} />
                                        </Form.Item>
                                        <Form.Item name="dokulmeYontemMateryal" label={t("sds.dokulmeYontemMateryal")}>
                                            <Input placeholder={t("sds.dokulmeYontemMateryalHint")} />
                                        </Form.Item>
                                        <KkdListesi name="dokulmeKkd" label={t("sds.dokulmeKkd")} />
                                    </>
                                ),
                            },
                            {
                                key: "07",
                                label: t("sds.tab07"),
                                children: (
                                    <>
                                        <Form.Item name="ellecleme" label={t("sds.ellecleme")}>
                                            <Input placeholder={t("sds.elleclemeHint")} />
                                        </Form.Item>
                                        <Form.Item name="depolama" label={t("sds.depolama")}>
                                            <Input placeholder={t("sds.depolamaHint")} />
                                        </Form.Item>
                                        <Form.Item name="depolamaSicakligi" label={t("sds.depolamaSicakligi")}>
                                            <Input placeholder={t("sds.depolamaSicakligiHint")} />
                                        </Form.Item>
                                        <Form.Item name="depolamaTedbirleri" label={t("sds.depolamaTedbirleri")}>
                                            <Input placeholder={t("sds.depolamaTedbirleriHint")} />
                                        </Form.Item>
                                        <Form.Item name="depolamaTankAmbalaj" label={t("sds.depolamaTankAmbalaj")}>
                                            <Input placeholder={t("sds.depolamaTankAmbalajHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "08",
                                label: t("sds.tab08"),
                                children: (
                                    <>
                                        <Divider orientation="left">8.1 Kontrol parametreleri</Divider>
                                        <Form.Item name="maruzKalmaLimitleri" label={t("sds.maruzKalmaLimitleri")}>
                                            <Input placeholder={t("sds.maruzKalmaLimitleriHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">Solunum</Divider>
                                        <Form.Item name="solunumTehlikeGurubu" label={t("sds.solunumTehlikeGurubu")}>
                                            <Input placeholder={t("sds.solunumTehlikeGurubuHint")} />
                                        </Form.Item>
                                        <Form.Item name="solunumMiktarGurubu" label={t("sds.solunumMiktarGurubu")}>
                                            <Input placeholder={t("sds.solunumMiktarGurubuHint")} />
                                        </Form.Item>
                                        <Form.Item name="solunumAcigaCikma" label={t("sds.solunumAcigaCikma")}>
                                            <Input placeholder={t("sds.solunumAcigaCikmaHint")} />
                                        </Form.Item>
                                        <Form.Item name="solunumKorumaKilavuzu" label={t("sds.solunumKorumaKilavuzu")}>
                                            <Input placeholder={t("sds.solunumKorumaKilavuzuHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">Cilt</Divider>
                                        <Form.Item name="ciltTehlikeGurubu" label={t("sds.ciltTehlikeGurubu")}>
                                            <Input placeholder={t("sds.ciltTehlikeGurubuHint")} />
                                        </Form.Item>
                                        <Form.Item name="ciltEtkiYuzeyi" label={t("sds.ciltEtkiYuzeyi")}>
                                            <Input placeholder={t("sds.ciltEtkiYuzeyiHint")} />
                                        </Form.Item>
                                        <Form.Item name="ciltEtkiSuresi" label={t("sds.ciltEtkiSuresi")}>
                                            <Input placeholder={t("sds.ciltEtkiSuresiHint")} />
                                        </Form.Item>
                                        <Form.Item name="ciltKorumaKilavuzu" label={t("sds.ciltKorumaKilavuzu")}>
                                            <Input placeholder={t("sds.ciltKorumaKilavuzuHint")} />
                                        </Form.Item>
                                        <Form.Item name="ciltCevreKorumaKilavuzu" label={t("sds.ciltCevreKorumaKilavuzu")}>
                                            <Input placeholder={t("sds.ciltCevreKorumaKilavuzuHint")} />
                                        </Form.Item>
                                        <Form.Item name="ciltUyariCumlesi" label={t("sds.ciltUyariCumlesi")}>
                                            <Input placeholder={t("sds.ciltUyariCumlesiHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">8.2 Maruz kalma kontrolleri</Divider>
                                        <Divider orientation="left">Mühendislik önlemleri</Divider>
                                        <Form.Item name="muhendislikOnlemleri" label={t("sds.muhendislikOnlemleri")}>
                                            <Input placeholder={t("sds.muhendislikOnlemleriHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">Bireysel koruyucu önlemler</Divider>
                                        <Form.Item name="bireyselKorumaGoz" label={t("sds.bireyselKorumaGoz")}>
                                            <Input placeholder={t("sds.bireyselKorumaGozHint")} />
                                        </Form.Item>
                                        <Form.Item name="bireyselKorumaCilt" label={t("sds.bireyselKorumaCilt")}>
                                            <Input placeholder={t("sds.bireyselKorumaCiltHint")} />
                                        </Form.Item>
                                        <Form.Item name="bireyselKorumaEl" label={t("sds.bireyselKorumaEl")}>
                                            <Input placeholder={t("sds.bireyselKorumaElHint")} />
                                        </Form.Item>
                                        <Form.Item name="bireyselKorumaSolunum" label={t("sds.bireyselKorumaSolunum")}>
                                            <Input placeholder={t("sds.bireyselKorumaSolunumHint")} />
                                        </Form.Item>
                                        <Form.Item name="bireyselKorumaIsılZarar" label={t("sds.bireyselKorumaIsılZarar")}>
                                            <Input placeholder={t("sds.bireyselKorumaIsılZararHint")} />
                                        </Form.Item>
                                        <Form.Item name="bireyselKorumaHijyen" label={t("sds.bireyselKorumaHijyen")}>
                                            <Input placeholder={t("sds.bireyselKorumaHijyenHint")} />
                                        </Form.Item>
                                        <Form.Item name="bireyselKorumaDiger" label={t("sds.bireyselKorumaDiger")}>
                                            <Input placeholder={t("sds.bireyselKorumaDigerHint")} />
                                        </Form.Item>
                                        <Form.Item name="bireyselKorumaEkBilgi" label={t("sds.bireyselKorumaEkBilgi")}>
                                            <Input placeholder={t("sds.bireyselKorumaEkBilgiHint")} />
                                        </Form.Item>
                                        <Divider orientation="left">Çevresel maruz kalma kontrolleri</Divider>
                                        <Form.Item name="cevreselMaruziyetKontrolleri" label={t("sds.cevreselMaruziyetKontrolleri")}>
                                            <Input placeholder={t("sds.cevreselMaruziyetKontrolleriHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "09",
                                label: t("sds.tab09"),
                                children: (
                                    <>
                                        <Form.Item name="maddeHali" label="Madde Hali">
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option value="K">{t("sds.kati")}</Select.Option>
                                                <Select.Option value="S">{t("sds.sivi")}</Select.Option>
                                                <Select.Option value="G">{t("sds.gaz")}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="gorunumForm" label={t("sds.gorunumForm")}>
                                            <Input placeholder={t("sds.gorunumFormHint")} />
                                        </Form.Item>
                                        <Form.Item name="renk" label={t("sds.renk")}>
                                            <Input placeholder={t("sds.renkHint")} />
                                        </Form.Item>
                                        <Form.Item name="koku" label={t("sds.koku")}>
                                            <Input placeholder={t("sds.kokuHint")} />
                                        </Form.Item>
                                        <Form.Item name="kokuEsigi" label={t("sds.kokuEsigi")}>
                                            <Input placeholder={t("sds.kokuEsigiHint")} />
                                        </Form.Item>
                                        <Form.Item name="yogunluk" label={t("sds.yogunluk")}>
                                            <Input placeholder={t("sds.yogunlukHint")} />
                                        </Form.Item>
                                        <Form.Item name="ph" label={t("sds.ph")}>
                                            <Input placeholder={t("sds.phHint")} />
                                        </Form.Item>
                                        <Form.Item name="erimeNoktasi" label={t("sds.erimeNoktasi")}>
                                            <Input placeholder={t("sds.erimeNoktasiHint")} />
                                        </Form.Item>
                                        <Form.Item name="donmaNoktasi" label={t("sds.donmaNoktasi")}>
                                            <Input placeholder={t("sds.donmaNoktasiHint")} />
                                        </Form.Item>
                                        <Form.Item name="kaynamaNoktasi" label={t("sds.kaynamaNoktasi")}>
                                            <Input placeholder={t("sds.kaynamaNoktasiHint")} />
                                        </Form.Item>
                                        <Form.Item name="kaynamaAraligi" label={t("sds.kaynamaAraligi")}>
                                            <Input placeholder={t("sds.kaynamaAraligi")} />
                                        </Form.Item>
                                        <Form.Item name="parlamaNoktasi" label={t("sds.parlamaNoktasi")}>
                                            <Input placeholder={t("sds.parlamaNoktasiHint")} />
                                        </Form.Item>
                                        <Form.Item name="buharlasmaHizi" label={t("sds.buharlasmaHizi")}>
                                            <Input placeholder={t("sds.buharlasmaHiziHint")} />
                                        </Form.Item>
                                        <Form.Item name="altAlevlenirlik" label={t("sds.altAlevlenirlik")}>
                                            <Input placeholder={t("sds.altAlevlenirlikHint")} />
                                        </Form.Item>
                                        <Form.Item name="ustAlevlenirlik" label={t("sds.ustAlevlenirlik")}>
                                            <Input placeholder={t("sds.ustAlevlenirlikHint")} />
                                        </Form.Item>
                                        <Form.Item name="patlayiciLimitleri" label={t("sds.patlayiciLimitleri")}>
                                            <Input placeholder={t("sds.patlayiciLimitleriHint")} />
                                        </Form.Item>
                                        <Form.Item name="kendiligindenTutusma" label={t("sds.kendiligindenTutusma")}>
                                            <Input placeholder={t("sds.kendiligindenTutusmaHint")} />
                                        </Form.Item>
                                        <Form.Item name="buharBasinci" label={t("sds.buharBasinci")}>
                                            <Input placeholder={t("sds.buharBasinciHint")} />
                                        </Form.Item>
                                        <Form.Item name="buharYogunlugu" label={t("sds.buharYogunlugu")}>
                                            <Input placeholder={t("sds.buharYogunluguHint")} />
                                        </Form.Item>
                                        <Form.Item name="cozunurluk" label={t("sds.cozunurluk")}>
                                            <Input placeholder={t("sds.cozunurlukHint")} />
                                        </Form.Item>
                                        <Form.Item name="dagilimKatsayisi" label={t("sds.dagilimKatsayisi")}>
                                            <Input placeholder={t("sds.dagilimKatsayisiHint")} />
                                        </Form.Item>
                                        <Form.Item name="bozunmaSicakligi" label={t("sds.bozunmaSicakligi")}>
                                            <Input placeholder={t("sds.bozunmaSicakligiHint")} />
                                        </Form.Item>
                                        <Form.Item name="akiskanlik" label={t("sds.akiskanlik")}>
                                            <Input placeholder={t("sds.akiskanlikHint")} />
                                        </Form.Item>
                                        <Form.Item name="patlayicilik" label={t("sds.patlayicilik")}>
                                            <Input placeholder={t("sds.patlayicilikHint")} />
                                        </Form.Item>
                                        <Form.Item name="oksitleyicilik" label={t("sds.oksitleyicilik")}>
                                            <Input placeholder={t("sds.oksitleyicilikHint")} />
                                        </Form.Item>
                                        <Form.Item name="dinamikViskozite" label={t("sds.dinamikViskozite")}>
                                            <Input placeholder={t("sds.dinamikViskoziteHint")} />
                                        </Form.Item>
                                        <Form.Item name="kinematikViskozite" label={t("sds.kinematikViskozite")}>
                                            <Input placeholder={t("sds.kinematikViskoziteHint")} />
                                        </Form.Item>
                                        <Form.Item name="ozellikEkBilgi" label={t("sds.ozellikEkBilgi")}>
                                            <Input placeholder={t("sds.ozellikEkBilgiHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "10",
                                label: t("sds.tab10"),
                                children: (
                                    <>
                                        <Form.Item name="reaksiyonOlasiligi" label={t("sds.reaksiyonOlasiligi")}>
                                            <Input placeholder={t("sds.reaksiyonOlasiligiHint")} />
                                        </Form.Item>
                                        <Form.Item name="uyumsuzMalzemeler" label={t("sds.uyumsuzMalzemeler")}>
                                            <Input placeholder={t("sds.uyumsuzMalzemelerHint")} />
                                        </Form.Item>
                                        <Form.Item name="kacinilmasiGereken" label={t("sds.kacinilmasiGereken")}>
                                            <Input placeholder={t("sds.kacinilmasiGerekenHint")} />
                                        </Form.Item>
                                        <Form.Item name="tehlikeliBilesenler" label={t("sds.tehlikeliBilesenler")}>
                                            <Input placeholder={t("sds.tehlikeliBilesenlerHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "11",
                                label: t("sds.tab11"),
                                children: (
                                    <>
                                        <Form.Item name="toksikolojikBilgiler" label={t("sds.toksikolojikBilgiler")}>
                                            <Input placeholder={t("sds.toksikolojikBilgilerHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "12",
                                label: t("sds.tab12"),
                                children: (
                                    <>
                                        <Form.Item name="ekolojikBilgiler" label={t("sds.ekolojikBilgiler")}>
                                            <Input placeholder={t("sds.ekolojikBilgilerHint")} />
                                        </Form.Item>
                                        <Form.Item name="zararliBozunmaUrunleri" label={t("sds.zararliBozunmaUrunleri")}>
                                            <Input placeholder={t("sds.zararliBozunmaUrunleriHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "13",
                                label: t("sds.tab13"),
                                children: (
                                    <>
                                        <Form.Item name="urunBertaraf" label={t("sds.urunBertaraf")}>
                                            <Input placeholder={t("sds.urunBertarafHint")} />
                                        </Form.Item>
                                        <Form.Item name="ambalajBertaraf" label={t("sds.ambalajBertaraf")}>
                                            <Input placeholder={t("sds.ambalajBertarafHint")} />
                                        </Form.Item>
                                        <Form.Item name="atikKodu" label={t("sds.atikKodu")}>
                                            <Input placeholder={t("sds.atikKoduHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "14",
                                label: t("sds.tab14"),
                                children: (
                                    <>
                                        <Form.Item name="tasimacilikBilgileri" label={t("sds.tasimacilikBilgileri")}>
                                            <Input placeholder={t("sds.tasimacilikBilgileriHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "15",
                                label: t("sds.tab15"),
                                children: (
                                    <>
                                        <Form.Item name="mevzuatBilgileri" label={t("sds.mevzuatBilgileri")}>
                                            <Input placeholder={t("sds.mevzuatBilgileriHint")} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                        ]}
                    />
                    <Modal
                        key="versiyonYayinlaModalForm"
                        open={versiyonModalOpen}
                        onOk={() => {
                            setVersiyonModalOpen(false);
                        }}
                        onCancel={() => {
                            setVersiyonModalOpen(false);
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setVersiyonModalOpen(false);
                                }}
                                style={{ marginRight: 20 }}
                            >
                                {t("genel.vazgec")}
                            </Button>,
                            <Button
                                key="ok"
                                type="primary"
                                form="kisiEkleForm"
                                onClick={() => {
                                    versiyonYayinlaClick();
                                }}
                            >
                                {t("genel.kaydet")}
                            </Button>,
                        ]}
                    >
                        <Form.Item name="versiyonKodu" label={t("adp.versiyonKodu")}>
                            <TextArea placeholder={t("adp.versiyonKoduHint")} autoSize={true} />
                        </Form.Item>
                        <Form.Item name="versiyonDetay" label={t("adp.versiyonDetay")}>
                            <TextArea placeholder={t("adp.versiyonDetayHint")} autoSize={true} />
                        </Form.Item>
                    </Modal>
                </Form>
            </Content>
        </Layout>
    );
}

export default Sds;
