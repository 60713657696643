import { Badge, Button, Calendar, Drawer, Layout, Modal, notification, Spin, Tag, Typography } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import { useKisininYetkileriniGetir } from "../hooks/useKisi";
import { useAksiyonListele } from "../hooks/useAksiyon";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import AksiyonGuncelle from "./AksiyonGuncelle";
const { Header, Content } = Layout;

function AksiyonList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const { aksiyonlar } = useAksiyonListele();
    const [aksID, setAksID] = useState();
    const { kisininYetkileri } = useKisininYetkileriniGetir();

    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setDataSource(aksiyonlar);
        setFilteredSource(aksiyonlar);
        setIsPending(false);
    }, [aksiyonlar]);

    const dateCellRender = (value) => {
        return dataSource.map((item, index) => {
            return value.format("YYYY-MM-DD") == dayjs(item.hedefTarih).format("YYYY-MM-DD") ? (
                <Badge
                    size="large"
                    status="error"
                    onClick={() => {
                        setAksID(item.aksiyonID);
                        setModalOpen(true);
                    }}
                    text={`${item.konum} ${item.aksiyonTipi}`}
                />
            ) : null;
        });
    };

    const ekleButon =
        kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("AKSI") || kisininYetkileri.includes("AKSIKG") ? (
            <Button
                key="ekleButon"
                type="primary"
                htmlType="submit"
                loading={isPending}
                danger={errMessage}
                size="small"
                onClick={() => {
                    setAksID(null);
                    setModalOpen(true);
                }}
            >
                {t("genel.ekle")}
            </Button>
        ) : null;

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="sayfaBaslik.aksiyonList" buttons={[ekleButon]} backURL="/" />
                {contextHolder}
                <Calendar
                    cellRender={dateCellRender}
                    fullscreen={true}
                    mode="month"
                    style={{ marginLeft: 10, marginRight: 10, marginBottom: 10, padding: 10 }}
                />
                <Drawer
                    open={modalOpen}
                    width={500}
                    onClose={() => {
                        setAksID(null);
                        setModalOpen(false);
                    }}
                >
                    <AksiyonGuncelle aksiyonID={aksID} />
                </Drawer>
            </Content>
        </Layout>
    );
}

export default AksiyonList;
