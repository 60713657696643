import { Form, Input, Tooltip } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import { CheckSquareFilled } from "@ant-design/icons";

function TcNoInput(props) {
    const { t } = useTranslation();

    return (
        <Form.Item
            name={props.name}
            label={props.label}
            rules={[
                {
                    warningOnly: true,
                    validator(_, tc) {
                        if (tc) {
                            if (tc.length === 11 && tc[0] !== "0") {
                                var tek = Number(tc[0]) + Number(tc[2]) + Number(tc[4]) + Number(tc[6]) + Number(tc[8]);
                                var cift = Number(tc[1]) + Number(tc[3]) + Number(tc[5]) + Number(tc[7]);
                                var t10 = (tek * 7 - cift) % 10;
                                if (Number(tc[9]) === t10 && Number(tc[10]) === (cift + tek + t10) % 10) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(t("kisi.invalidTckn"));
                                }
                            } else {
                                return Promise.reject(t("kisi.invalidTckn"));
                            }
                        } else {
                            return Promise.reject(t("kisi.invalidTckn"));
                        }
                    },
                    message: t("kisi.invalidTckn"),
                },
            ]}
        >
            <Input
                placeholder={props.hint}
                maxLength={11}
                suffix={
                    props.onayli ? (
                        <Tooltip title={t("kisi.nviOnayladi")}>
                            <CheckSquareFilled style={{ fontSize: "20px", color: "#0A0" }} />
                        </Tooltip>
                    ) : null
                }
                type="number"
            />
        </Form.Item>
    );
}

export default TcNoInput;
