import { Form, Button, DatePicker, Input, notification, Popconfirm, Space, Spin } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { CheckOutlined } from "@ant-design/icons";
import { useKisininYetkileriniGetir } from "../hooks/useKisi";
import { useAksiyonGetir, useAksiyonKaydet, useAksiyonSil } from "../hooks/useAksiyon";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import SahaDegerleri from "../components/SahaDegerleri";
import BolumSelect from "../components/BolumSelect";
import KisiSelect from "../components/KisiSelect";

const { TextArea } = Input;

function AksiyonGuncelle(props) {
    let id = props ? (props.aksiyonID ? props.aksiyonID : null) : null;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const [aksiyonDurumu, setAksiyonDurumu] = useState();
    const { kisininYetkileri } = useKisininYetkileriniGetir();
    const { firmaID } = useGetUserInfo();
    const [silebilir, setSilebilir] = useState();
    const [formAktif, setFormAktif] = useState();

    //const [aksiyon] = useAksiyonGetir(id);
    const { aksiyonKaydet } = useAksiyonKaydet(id);
    const { aksiyonSil } = useAksiyonSil(id);

    useEffect(() => {
        setSilebilir(false);
        if (id) {
            if (kisininYetkileri.includes("ADMIN")) setSilebilir(true);
            if (kisininYetkileri.includes("AKSI")) setSilebilir(true);
            if (kisininYetkileri.includes("AKSIKS")) setSilebilir(true);
        }
        setFormAktif(false);
        if (kisininYetkileri.includes("ADMIN")) setFormAktif(true);
        if (kisininYetkileri.includes("AKSI")) setFormAktif(true);
        if (kisininYetkileri.includes("AKSIKD")) setFormAktif(true);
    }, [kisininYetkileri]);

    useEffect(() => {
        form.setFieldsValue({
            aksiyonTipi: null,
            bolumID: null,
            konum: null,
            hedefTarih: null,
            aciklama: null,
            kapanisZamani: null,
            kapanisNotu: null,
            sorumlular: null,
        });
        setAksiyonDurumu(null);

        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
        fetch(`http://89.19.24.18:3001/Aksiyon/${id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data) {
                    form.setFieldsValue({
                        aksiyonTipi: data.aksiyonTipi,
                        bolumID: data.bolumID,
                        konum: data.konum,
                        hedefTarih: data.hedefTarih ? dayjs(data.hedefTarih) : null,
                        aciklama: data.aciklama,
                        kapanisZamani: data.kapanisZamani ? dayjs(data.kapanisZamani) : null,
                        kapanisNotu: data.kapanisNotu,
                        sorumlular: data.sorumlular,
                    });
                    setAksiyonDurumu(data.aksiyonDurumu);
                }
            });
    }, [props]);

    return (
        <Form
            form={form}
            name="aksiyonGuncelleForm"
            disabled={!formAktif}
            autoComplete="off"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 16 }}
            onFinish={(e) => {
                console.log("aksiyon : ", e);
                if (id != null) e.aksiyonID = id;
                if (props.ilgiliTipi != null) e.ilgiliTipi = props.ilgiliTipi;
                if (props.ilgiliID != null) e.ilgiliID = props.ilgiliID;
                aksiyonKaydet({ e });
                api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
            }}
            onFinishFailed={(error) => {
                console.log("onFinishFailed : ", error);
            }}
        >
            {contextHolder}
            <SahaDegerleri name="aksiyonTipi" id="aksiyon.aksiyonTipi" label={t("aksiyon.aksiyonTipi")} />
            <BolumSelect name="bolumID" label={t("aksiyon.bolum")} hint={t("aksiyon.bolumHint")} />
            <SahaDegerleri name="konum" id="aksiyon.konum" label={t("aksiyon.konum")} hint={t("aksiyon.konumHint")} />
            <Form.Item name="hedefTarih" label={t("aksiyon.hedefTarih")}>
                <DatePicker />
            </Form.Item>
            <Form.Item name="aciklama" label={t("aksiyon.aciklama")}>
                <TextArea placeholder={t("aksiyon.aciklamaHint")} autoSize={true} />
            </Form.Item>
            <KisiSelect name="sorumlular" firmaID={firmaID} label={t("aksiyon.sorumlular")} mode="tags" />

            {aksiyonDurumu === "K" && (
                <Form.Item name="kapanisZamani" label={t("aksiyon.kapanisZamani")}>
                    <DatePicker showTime disabled />
                </Form.Item>
            )}
            {aksiyonDurumu && (
                <Form.Item name="kapanisNotu" label={t("aksiyon.kapanisNotu")}>
                    <Input
                        placeholder={t("aksiyon.kapanisNotuHint")}
                        disabled={aksiyonDurumu === "K"}
                        suffix={
                            aksiyonDurumu != "K" && (
                                <Button
                                    key="aksiyonKapatButon"
                                    type="primary"
                                    style={{ backgroundColor: "green", margin: -12 }}
                                    onClick={() => {
                                        const e = {
                                            aksiyonID: id,
                                            aksiyonDurumu: "K",
                                            kapanisNotu: form.getFieldValue("kapanisNotu"),
                                            kapanisZamani: Date.now(),
                                        };
                                        aksiyonKaydet({ e });
                                        setAksiyonDurumu("K");
                                    }}
                                >
                                    {t("genel.kapat")}
                                </Button>
                            )
                        }
                    />
                </Form.Item>
            )}
            <Space style={{ width: "100%", justifyContent: "end" }}>
                {silebilir && (
                    <Popconfirm
                        key="aksiyonSilOnay"
                        placement="bottomRight"
                        title={t("genel.eminMisin")}
                        description={t("genel.silinecek")}
                        okText={t("genel.sil")}
                        cancelText={t("genel.vazgec")}
                        onConfirm={() => {
                            aksiyonSil(id);
                            navigate("/aksiyonList");
                        }}
                        style={{ width: 400 }}
                    >
                        <Button key="aksiyonSilButon" type="primary" danger>
                            {t("genel.sil")}
                        </Button>
                    </Popconfirm>
                )}
                {formAktif && (
                    <Button key="ok" type="primary" form="aksiyonGuncelleForm" htmlType="submit">
                        {t("genel.kaydet")}
                    </Button>
                )}
            </Space>
        </Form>
    );
}

export default AksiyonGuncelle;
