import { React } from "react";
import { Form, Layout } from "antd";
import PhoneInput from "antd-phone-input";

const { Content } = Layout;

function Telefon() {
    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form id="rolEkleForm" autoComplete="off" layout="vertical" style={{ margin: 30 }}>
                    <Form.Item name="isim" label="Telefon">
                        <PhoneInput defaultCountry={"TR"} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default Telefon;
