import { Form, Select } from "antd";
import { React, useEffect, useRef, useState } from "react";

function KisiSelect(props) {
    const [kisiler, setKisiler] = useState([]);
    const [kisiOptions, setKisiOptions] = useState([]);
    const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};

    const kisileriGetir = async () => {
        let unsubscribe;
        fetch(`http://89.19.24.18:3001/Kisi?${new URLSearchParams({ firmaID: props.firmaID }).toString()}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                unsubscribe = data;
                setKisiler(data);
            });
        setKisiler(unsubscribe);
    };

    useEffect(() => {
        if (kisiler) {
            const opts = kisiler.map((item) => ({ value: String(item.kisiID), label: `${item.isim} ${item.soyad}` }));
            setKisiOptions(opts);
        }
    }, [kisiler]);

    useEffect(() => {
        kisileriGetir();
    }, []);

    return (
        <Form.Item name={props.name} label={props.label}>
            <Select placeholder={props.hint} mode={props.mode} disabled={props.disabled} dropdownRender={(menu) => <>{menu}</>}>
                {kisiOptions.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                        {item.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}

export default KisiSelect;
