import { Avatar, Badge, Button, Input, Layout, List, Modal, notification, Space, Spin, Switch, Typography } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import BildirimEkle from "./BildirimEkle";
import { useBildirimleriGetir } from "../hooks/useBildirim";
import { AuditOutlined, SearchOutlined } from "@ant-design/icons";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
const { Header, Content } = Layout;

function BildirimList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [durumY, setDurumY] = useState(true);
    const [durumE, setDurumE] = useState(true);
    const [durumH, setDurumH] = useState(false);
    const [durumK, setDurumK] = useState(false);
    const { bildirimler } = useBildirimleriGetir();
    const [api, contextHolder] = notification.useNotification();
    const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
    var kisiler = [];

    useEffect(() => {
        setDataSource(bildirimler);
        setFilteredSource(bildirimler);
        searchData("");
        setIsPending(false);
    }, [bildirimler]);

    useEffect(() => {
        searchData("");
    }, [durumE, durumH, durumK, durumY]);

    const ekleButon = (
        <Button
            key="ekleButon"
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger={errMessage}
            size="small"
            onClick={() => {
                console.log("kişiler :", kisiler);
                //setModalOpen(true);
            }}
        >
            {t("genel.ekle")}
        </Button>
    );

    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        setFilteredSource(
            dataSource.filter((item) => {
                const aciklama = item.aciklama ? item.aciklama.toLowerCase() : "";
                const comparisonString = `${aciklama}`;
                if (!durumY && item.durumu === "Y") return false;
                if (!durumE && item.durumu === "E") return false;
                if (!durumH && item.durumu === "H") return false;
                if (!durumK && item.durumu === "K") return false;
                return comparisonString.includes(searchText.toLowerCase());
            })
        );
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="sayfaBaslik.bildirimList" buttons={[ekleButon]} backURL="/" />
                {contextHolder}
                <Space align="center">
                    <Input
                        key="searchBox"
                        placeholder={t("genel.ara")}
                        prefix={<SearchOutlined />}
                        style={{ margin: 10, width: 300 }}
                        onChange={(e) => {
                            searchData(e.target.value);
                        }}
                    />
                    <Switch checkedChildren="Yeni" unCheckedChildren="Yeni" defaultChecked={durumY} onChange={(val) => setDurumY(val)} />
                    <Switch checkedChildren="Açık" unCheckedChildren="Açık" defaultChecked={durumE} onChange={(val) => setDurumE(val)} />
                    <Switch checkedChildren="Kapalı" unCheckedChildren="Kapalı" defaultChecked={durumK} onChange={(val) => setDurumK(val)} />
                    <Switch checkedChildren="Reddedildi" unCheckedChildren="Reddedildi" defaultChecked={durumH} onChange={(val) => setDurumH(val)} />
                </Space>
                <List
                    style={{ margin: 20 }}
                    bordered
                    dataSource={filteredSource}
                    renderItem={(item) => {
                        return (
                            <Badge.Ribbon
                                text={item.retNedeni ? item.retNedeni : "."}
                                color={item.durumu === "Y" ? "gray" : item.durumu === "E" ? "blue" : item.durumu === "H" ? "red" : "green"}
                            >
                                <List.Item
                                    key={item.bildirimID}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        navigate(`/bildirimGuncelle/${item.bildirimID}`);
                                    }}
                                >
                                    <Space>
                                        <Avatar
                                            key="kisiAvatar"
                                            src={`http://app.kmysistem.com/uploads/kisi/${item.bildirenID}/avatar.png`}
                                            style={{ marginTop: -5, marginBottom: -5, marginLeft: -10, width: 38, height: 38 }}
                                            title={item.bildirenID}
                                        />
                                        <Typography.Text
                                            style={{
                                                color:
                                                    item.durumu === "Y"
                                                        ? "blue"
                                                        : item.durumu === "E"
                                                        ? "black"
                                                        : item.durumu === "H"
                                                        ? "red"
                                                        : "#888",
                                            }}
                                        >
                                            {item.aciklama}
                                        </Typography.Text>
                                    </Space>
                                </List.Item>
                            </Badge.Ribbon>
                        );
                    }}
                />
                <Modal
                    key="modalForm"
                    open={modalOpen}
                    onOk={() => {
                        setModalOpen(false);
                    }}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="bildirimEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <BildirimEkle />
                </Modal>
            </Content>
        </Layout>
    );
}

export default BildirimList;
