import { Form, Select } from "antd";
import { React, useEffect, useRef, useState } from "react";

function FirmaSelect(props) {
    const [firmalar, setFirmalar] = useState([]);
    const [firmaOptions, setFirmaOptions] = useState([]);
    const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};

    const firmalariGetir = async () => {
        let unsubscribe;

        fetch(`http://89.19.24.18:3001/Firma?${new URLSearchParams({ tmgdk: props.tmgdk ? true : null }).toString()}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                unsubscribe = data;
                setFirmalar(data);
            });
        setFirmalar(unsubscribe);
    };

    useEffect(() => {
        if (firmalar) {
            const opts = firmalar.map((item) => ({ value: String(item.firmaID), label: item.firmaismi }));
            setFirmaOptions(opts);
        }
    }, [firmalar]);

    useEffect(() => {
        firmalariGetir();
    }, []);

    const kisiRef = useRef(null);

    return (
        <Form.Item name={props.name} label={props.label}>
            <Select placeholder={props.hint} mode={props.mode} disabled={props.disabled} dropdownRender={(menu) => <>{menu}</>}>
                {firmaOptions.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                        {item.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}

export default FirmaSelect;
