import { useGetToken } from "./useGetToken";
import { useEffect, useState } from "react";
import { v4 } from "uuid";

export const useAksiyonKaydet = () => {
    const { accessToken } = useGetToken();
    const aksiyonKaydet = async ({ e }) => {
        console.log("useAksiyon e :", e);
        fetch(`http://89.19.24.18:3001/Aksiyon/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("aksiyon kayıt cevabı : ", data);
            });
    };
    return { aksiyonKaydet };
};

export const useAksiyonSil = (id) => {
    const { accessToken } = useGetToken();
    const aksiyonSil = async () => {
        fetch(`http://89.19.24.18:3001/Aksiyon/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { aksiyonSil };
};

export const useAksiyonListele = (ilgiliTipi, ilgiliID) => {
    const { accessToken } = useGetToken();
    const [aksiyonlar, setAksiyonlar] = useState([]);
    const aksiyonListele = async () => {
        fetch(`http://89.19.24.18:3001/Aksiyon/${ilgiliTipi}/${ilgiliID}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setAksiyonlar(data);
            });

        return () => aksiyonlar;
    };
    useEffect(() => {
        aksiyonListele();
    }, []);

    return { aksiyonlar };
};

export const useAksiyonGetir = (id) => {
    const [aksiyon, setAksiyon] = useState([]);
    const { accessToken } = useGetToken();
    const aksiyonGetir = async () => {
        fetch(`http://89.19.24.18:3001/Aksiyon/byId/${id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setAksiyon(data);
            });

        return () => aksiyon;
    };

    useEffect(() => {
        aksiyonGetir();
    }, []);

    return { aksiyon };
};
