import { Form, Input, Layout } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import { useKursBolumEkle } from "../hooks/useKursBolum";

const { Content } = Layout;

function KursBolumEkle(props) {
    const { t } = useTranslation();
    const { kursBolumEkle } = useKursBolumEkle();

    const onSubmit = (e) => {
        console.log("ekle : ", props, e);
        kursBolumEkle({
            kursID: props.kursID,
            kursBolumIsmi: e.kursBolumIsmi,
        });
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="kursBolumEkleForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    <Form.Item
                        name="kursBolumIsmi"
                        label={t("kursBolum.kursBolumIsmi")}
                        rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                    >
                        <Input placeholder={t("kursBolum.kursBolumIsmiHint")} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default KursBolumEkle;
