import React, { useEffect, useState } from "react";
import SGKgorevKodlari from "../constant/SGKgorevKodlari";
import { useTranslation } from "react-i18next";
import { Form, Select } from "antd";

function SgkGorevKodu(props) {
    const { t } = useTranslation();
    const [gorevKoduDataSource, setGorevKoduDataSource] = useState([]);
    const [filteredGorevKoduDataSource, setFilteredGorevKoduDataSource] = useState([]);

    useEffect(() => {
        setGorevKoduDataSource(SGKgorevKodlari);
        setFilteredGorevKoduDataSource(SGKgorevKodlari);
    }, [SGKgorevKodlari]);

    const onSearch = (searchText) => {
        setFilteredGorevKoduDataSource(gorevKoduDataSource);
        if (searchText) {
            setFilteredGorevKoduDataSource(
                gorevKoduDataSource.filter((item) => {
                    const codeName = item.label ? item.label.toLowerCase() : "";
                    return codeName.includes(searchText.toLowerCase());
                })
            );
        }
    };

    return (
        <Form.Item name={props.name} label={props.label}>
            <Select
                placeholder={t("genel.seciniz")}
                showSearch
                optionFilterProp="label"
                onSearch={onSearch}
                options={filteredGorevKoduDataSource}
            ></Select>
        </Form.Item>
    );
}

export default SgkGorevKodu;
