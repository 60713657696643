import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, List, Modal, Space, Spin, Tooltip } from "antd";
import { EditOutlined, EyeOutlined, LikeFilled, DislikeFilled, ClockCircleFilled } from "@ant-design/icons";
import { useKisininYetkileriniGetir } from "../hooks/useKisi";
import BelgeGuncelle from "./BelgeGuncelle";
import BelgeEkle from "./BelgeEkle";
import { useBelgeTipleriniGetir } from "../hooks/useBelge";

function Belgeler(props) {
    const { t } = useTranslation();
    const [docUpdateModalOpen, setDocUpdateModalOpen] = useState(false);
    const [docModalOpen, setDocModalOpen] = useState(false);

    const { belgeTipleri } = useBelgeTipleriniGetir(props.id, props.sahip);
    const { kisininYetkileri } = useKisininYetkileriniGetir();
    const [filteredDocSource, setFilteredDocSource] = useState([]);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [docsDataSource, setDocsDataSource] = useState([]);
    const [belgeler, setBelgeler] = useState();
    const [docProps, setDocProps] = useState();
    const [docTypeList, setDocTypeList] = useState([]);

    useEffect(() => {
        belgeleriGetir();
    }, []);

    useEffect(() => {
        setTimeout(function () {
            belgeleriGetir();
        }, 1000);
    }, [docModalOpen, docUpdateModalOpen]);

    useEffect(() => {
        setDocTypeList(belgeTipleri);
    }, [belgeTipleri]);

    useEffect(() => {
        setDocsDataSource(belgeler);
        setFilteredDocSource(belgeler);
        setLoading(false);
    }, [belgeler]);

    const belgeleriGetir = async () => {
        const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};
        let unsubscribe;
        fetch(`http://89.19.24.18:3001/Belge/${props.id}/${props.sahip}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                unsubscribe = data;
                console.log("data : ", data);
                setBelgeler(data);
                setLoading(false);
            });

        setBelgeler(unsubscribe);
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <>
            <List
                style={{ margin: 20 }}
                header={
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Button
                            type="dashed"
                            onClick={() => {
                                setDocModalOpen(true);
                            }}
                        >
                            {t("genel.belgeEkle")}
                        </Button>
                    </Space>
                }
                bordered
                dataSource={filteredDocSource}
                renderItem={(item) => (
                    <List.Item key={item.id}>
                        <Space style={{ width: "100%", justifyContent: "space-between" }}>
                            <Space>
                                {(kisininYetkileri.includes("ADMIN") ||
                                    kisininYetkileri.includes(`${props.sahip}`) ||
                                    kisininYetkileri.includes(`${props.sahip}BG`)) && (
                                    <Button
                                        type="text"
                                        icon={<EyeOutlined style={{ fontSize: 18 }} />}
                                        style={{ color: "black", marginLeft: -20 }}
                                        onClick={() => {
                                            window.open(item.belgeURL, "_blank");
                                        }}
                                    />
                                )}
                                {(kisininYetkileri.includes("ADMIN") ||
                                    kisininYetkileri.includes(`${props.sahip}`) ||
                                    kisininYetkileri.includes(`${props.sahip}BD`)) && (
                                    <Button
                                        type="text"
                                        icon={<EditOutlined style={{ fontSize: 18 }} />}
                                        style={{ color: "black", marginLeft: -5 }}
                                        onClick={() => {
                                            console.log("clicked : ", item);
                                            setDocProps({
                                                id: item.id,
                                                belgeTipiID: item.belgeTipiID,
                                                belgeIsmi: item.belgeIsmi,
                                                durumAciklamasi: item.durumAciklamasi,
                                                verilisTarihi: item.verilisTarihi,
                                                gecerlikTarihi: item.gecerlikTarihi,
                                            });
                                            setDocUpdateModalOpen(true);
                                        }}
                                    />
                                )}
                                {item.belgeIsmi}
                            </Space>
                            <Tooltip title={item.belgeDurumu === "hayir" ? item.durumAciklamasi : t(`belgeDurumu.${item.belgeDurumu}`)}>
                                {item.belgeDurumu === "yeni" ? (
                                    <ClockCircleFilled style={{ color: "#aaa", fontSize: 20 }} />
                                ) : item.belgeDurumu === "hayir" ? (
                                    <DislikeFilled style={{ color: "red", fontSize: 20 }} />
                                ) : (
                                    <LikeFilled style={{ color: "green", fontSize: 20 }} />
                                )}
                            </Tooltip>
                        </Space>
                    </List.Item>
                )}
            />
            <Modal
                key="docModalForm"
                open={docModalOpen}
                onOk={() => {
                    setDocModalOpen(false);
                }}
                onCancel={() => {
                    setDocModalOpen(false);
                }}
                footer={[
                    <Button
                        key="cancel"
                        onClick={() => {
                            setDocModalOpen(false);
                        }}
                        style={{ marginRight: 20 }}
                    >
                        {t("genel.vazgec")}
                    </Button>,
                    <Button
                        key="ok"
                        type="primary"
                        form="docAddForm"
                        htmlType="submit"
                        onClick={() => {
                            setDocModalOpen(false);
                        }}
                    >
                        {t("genel.kaydet")}
                    </Button>,
                ]}
            >
                <BelgeEkle belgeTipi={props.sahip} id={props.id} typeList={docTypeList} />
            </Modal>
            <Modal
                key="docUpdateModalForm"
                open={docUpdateModalOpen}
                onOk={() => {
                    setDocUpdateModalOpen(false);
                }}
                onCancel={() => {
                    setDocUpdateModalOpen(false);
                }}
                footer={[
                    <Button
                        key="cancel"
                        onClick={() => {
                            setDocUpdateModalOpen(false);
                        }}
                        style={{ marginRight: 20 }}
                    >
                        {t("genel.vazgec")}
                    </Button>,
                    <Button
                        key="ok"
                        type="primary"
                        form="docUpdateForm"
                        htmlType="submit"
                        onClick={() => {
                            setDocUpdateModalOpen(false);
                        }}
                    >
                        {t("genel.kaydet")}
                    </Button>,
                ]}
            >
                <BelgeGuncelle fieldValues={docProps} id={props.id} typeList={docTypeList} />
            </Modal>
        </>
    );
}

export default Belgeler;
