import { Form, Button, DatePicker, Input, message, notification, Popconfirm, Select, Space, Spin, Switch, Upload, Tooltip } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useYanginTupuGetir, useYanginTupuGuncelle, useYanginTupuSil } from "../hooks/useYanginTupu";
import KmyHeader from "./KmyHeader";
import "react-quill/dist/quill.snow.css";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";

const { TextArea } = Input;

function YanginTupuGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [yantupID, setYantupID] = useState();

    const { yanginTupu } = useYanginTupuGetir(id);
    const { yanginTupuGuncelle } = useYanginTupuGuncelle(id);
    const { yanginTupuSil } = useYanginTupuSil(id);

    const yanginTupuKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button type="primary" htmlType="submit" loading={isPending} danger={errMessage} key="chapterSaveButton">
            {t("genel.kaydet")}
        </Button>
    );

    const yanginTupuSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="yanginTupuSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                yanginTupuSil(id);
                navigate(`/yanginTupuGuncelle/${yantupID}`);
            }}
            style={{ width: 400 }}
        >
            <Button type="primary" danger key="yanginTupuSilButon">
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (yanginTupu) {
            form.setFieldsValue({
                demirbasNo: yanginTupu.demirbasNo,
                icerik: yanginTupu.icerik,
                standartTuru: yanginTupu.standartTuru,
                uretici: yanginTupu.uretici,
                aciklama: yanginTupu.aciklama,
                bina: yanginTupu.bina,
                konum: yanginTupu.konum,
                bosAgirlik: yanginTupu.bosAgirlik,
                doluAgirlik: yanginTupu.doluAgirlik,
                asgariAgirlik: yanginTupu.asgariAgirlik,
                kontrolPeriyodu: yanginTupu.kontrolPeriyodu,
                dolumPeriyodu: yanginTupu.dolumPeriyodu,
                yanginSinifi: yanginTupu.yanginSinifi,
                uretimTarihi: yanginTupu.uretimTarihi ? dayjs(yanginTupu.uretimTarihi) : null,
                dolumTarihi: yanginTupu.dolumTarihi ? dayjs(yanginTupu.dolumTarihi) : null,
                planlananKontrol: yanginTupu.planlananKontrol ? dayjs(yanginTupu.planlananKontrol) : null,
                planlananDolum: yanginTupu.planlananDolum ? dayjs(yanginTupu.planlananDolum) : null,
            });
            setYantupID(yanginTupu.yantupID);
            setLoading(false);
            console.log("yantupID : ", yantupID);
        }
    }, [yanginTupu]);

    const onSubmit = (e) => {
        setIsPending(true);
        yanginTupuGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Form
            form={form}
            disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
            autoComplete="off"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 14 }}
            onFinish={(values) => {
                onSubmit(values);
            }}
            onFinishFailed={(error) => {
                console.log("onFinishFailed : ", error);
            }}
        >
            <KmyHeader caller="sayfaBaslik.yanginTupuGuncelle" buttons={[yanginTupuKaydetButon, yanginTupuSilButon]} backURL="/yanginTupuList" />
            {contextHolder}
            <Form.Item name="demirbasNo" label={t("yanginTupu.demirbasNo")} style={{ paddingTop: 20 }}>
                <Input
                    placeholder={t("yanginTupu.demirbasNoHint")}
                    danger={yanginTupu.problemliMi}
                    addonAfter={yanginTupu ? yanginTupu.yantupID : null}
                />
            </Form.Item>
            <Form.Item name="icerik" label={t("yanginTupu.icerik")}>
                <Input placeholder={t("yanginTupu.icerikHint")} />
            </Form.Item>
            <Form.Item name="standartTuru" label={t("yanginTupu.standartTuru")}>
                <Select placeholder={t("genel.seciniz")}>
                    <Select.Option value="1">{t("yanginStandartTuru.1")}</Select.Option>
                    <Select.Option value="2">{t("yanginStandartTuru.2")}</Select.Option>
                    <Select.Option value="3">{t("yanginStandartTuru.3")}</Select.Option>
                    <Select.Option value="4">{t("yanginStandartTuru.4")}</Select.Option>
                    <Select.Option value="5">{t("yanginStandartTuru.5")}</Select.Option>
                    <Select.Option value="0">{t("yanginStandartTuru.0")}</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="uretici" label={t("yanginTupu.uretici")}>
                <Input placeholder={t("yanginTupu.ureticiHint")} />
            </Form.Item>
            <Form.Item name="aciklama" label={t("yanginTupu.aciklama")}>
                <Input placeholder={t("yanginTupu.aciklamaHint")} />
            </Form.Item>
            <Form.Item name="bina" label={t("yanginTupu.bina")}>
                <Input placeholder={t("yanginTupu.binaHint")} />
            </Form.Item>
            <Form.Item name="konum" label={t("yanginTupu.konum")}>
                <Input placeholder={t("yanginTupu.konumHint")} />
            </Form.Item>
            <Form.Item label={t("yanginTupu.agirlik")}>
                <Space.Compact>
                    <Tooltip title={t("yanginTupu.bosAgirlikHint")}>
                        <Form.Item name="bosAgirlik" style={{ width: "100%", marginBottom: 0, marginRight: 5 }}>
                            <Input placeholder={t("yanginTupu.bosAgirlikHint")} addonAfter={t("genel.gr")} />
                        </Form.Item>
                    </Tooltip>
                    <Tooltip title={t("yanginTupu.doluAgirlikHint")}>
                        <Form.Item name="doluAgirlik" style={{ width: "100%", marginBottom: 0, marginRight: 5 }}>
                            <Input placeholder={t("yanginTupu.doluAgirlikHint")} addonAfter={t("genel.gr")} />
                        </Form.Item>
                    </Tooltip>
                    <Tooltip title={t("yanginTupu.asgariAgirlikHint")}>
                        <Form.Item name="asgariAgirlik" style={{ width: "100%", marginBottom: 0 }}>
                            <Input placeholder={t("yanginTupu.asgariAgirlikHint")} addonAfter={t("genel.gr")} />
                        </Form.Item>
                    </Tooltip>
                </Space.Compact>
            </Form.Item>
            <Form.Item label={t("yanginTupu.tarih")}>
                <Space.Compact style={{ width: "100%" }}>
                    <Tooltip title={t("yanginTupu.uretimTarihiHint")}>
                        <Form.Item name="uretimTarihi" style={{ width: "100%", marginBottom: 0, marginRight: 5 }}>
                            <DatePicker
                                style={{ width: "100%" }}
                                locale={locale}
                                format={"YYYY/MM/DD"}
                                placeholder={t("yanginTupu.uretimTarihiHint")}
                            />
                        </Form.Item>
                    </Tooltip>
                    <Tooltip title={t("yanginTupu.dolumTarihiHint")}>
                        <Form.Item name="dolumTarihi" style={{ width: "100%", marginBottom: 0 }}>
                            <DatePicker
                                style={{ width: "100%" }}
                                locale={locale}
                                format={"YYYY/MM/DD"}
                                placeholder={t("yanginTupu.dolumTarihiHint")}
                            />
                        </Form.Item>
                    </Tooltip>
                </Space.Compact>
            </Form.Item>
            <Form.Item label={t("yanginTupu.periyot")}>
                <Space.Compact style={{ width: "100%" }}>
                    <Tooltip title={t("yanginTupu.kontrolPeriyoduHint")}>
                        <Form.Item name="kontrolPeriyodu" style={{ width: "100%", marginBottom: 0, marginRight: 5 }}>
                            <Input placeholder={t("yanginTupu.kontrolPeriyoduHint")} addonAfter={t("genel.ay")} />
                        </Form.Item>
                    </Tooltip>
                    <Tooltip title={t("yanginTupu.dolumPeriyoduHint")}>
                        <Form.Item name="dolumPeriyodu" style={{ width: "100%", marginBottom: 0 }}>
                            <Input placeholder={t("yanginTupu.dolumPeriyoduHint")} addonAfter={t("genel.ay")} />
                        </Form.Item>
                    </Tooltip>
                </Space.Compact>
            </Form.Item>
            <Form.Item label={t("yanginTupu.planlanan")}>
                <Space.Compact style={{ width: "100%" }}>
                    <Tooltip title={t("yanginTupu.planlananKontrolHint")}>
                        <Form.Item name="planlananKontrol" style={{ width: "100%", marginBottom: 0, marginRight: 5 }}>
                            <DatePicker
                                style={{ width: "100%" }}
                                locale={locale}
                                format={"YYYY/MM/DD"}
                                placeholder={t("yanginTupu.planlananKontrolHint")}
                            />
                        </Form.Item>
                    </Tooltip>
                    <Tooltip title={t("yanginTupu.planlananDolumHint")}>
                        <Form.Item name="planlananDolum" style={{ width: "100%", marginBottom: 0 }}>
                            <DatePicker
                                style={{ width: "100%" }}
                                locale={locale}
                                format={"YYYY/MM/DD"}
                                placeholder={t("yanginTupu.planlananDolumHint")}
                            />
                        </Form.Item>
                    </Tooltip>
                </Space.Compact>
            </Form.Item>
            <Form.Item name="yanginSinifi" label={t("yanginTupu.yanginSinifi")}>
                <Select placeholder={t("genel.seciniz")} mode="tags">
                    <Select.Option value="A">{t("yanginSinifi.A")}</Select.Option>
                    <Select.Option value="B">{t("yanginSinifi.B")}</Select.Option>
                    <Select.Option value="C">{t("yanginSinifi.C")}</Select.Option>
                    <Select.Option value="D">{t("yanginSinifi.D")}</Select.Option>
                    <Select.Option value="K">{t("yanginSinifi.K")}</Select.Option>
                </Select>
            </Form.Item>
        </Form>
    );
}

export default YanginTupuGuncelle;
