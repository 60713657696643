import ulkeililce from "../constant/ulkeililce.json";
import naceKodlari from "../constant/nace.json";
import {
    Form,
    Button,
    Cascader,
    ConfigProvider,
    Checkbox,
    DatePicker,
    Divider,
    Input,
    Layout,
    message,
    notification,
    Popconfirm,
    Select,
    Space,
    Spin,
    Tabs,
    Upload,
} from "antd";
import MaskedInput from "antd-mask-input";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";
import PhoneInput, { locale as phoneLocale } from "antd-phone-input";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useFirmayiGetir, useFirmaGuncelle, useFirmaSil } from "../hooks/useFirma";
import { useKisininYetkileriniGetir } from "../hooks/useKisi";
import locale from "antd/es/date-picker/locale/tr_TR";
import "dayjs/locale/tr.js";
import dayjs from "dayjs";
import KmyHeader from "./KmyHeader";
import KisiSelect from "../components/KisiSelect";
import Belgeler from "./Belgeler";
import FirmaSelect from "../components/FirmaSelect";

const { Content } = Layout;

function FirmaGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [kmyCustomer, setKmyCustomer] = useState(false);
    const [tmgdk, setTmgdk] = useState(false);
    const [uetdsCustomer, setUetdsCustomer] = useState(false);

    const [form] = Form.useForm();
    const { firma } = useFirmayiGetir(id);
    const [logoURL, setLogoURL] = useState();
    const [loading, setLoading] = useState(false);
    // const [naceKisimKodu, setNaceKisimKodu] = useState();
    const [tehlikeSinifiKodu, setTehlikeSinifiKodu] = useState();
    const [naceKisim, setNaceKisim] = useState();

    const { kisininYetkileri } = useKisininYetkileriniGetir();
    const { firmaGuncelle } = useFirmaGuncelle(id);
    const { firmaSil } = useFirmaSil(id);
    const [formPasif, setFormPasif] = useState();

    const firmaKaydetButton = (kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("FIRMKY") || kisininYetkileri.includes("FIRM")) && (
        <Button key="saveButton" type="primary" htmlType="submit" loading={loading} size="small">
            {t("genel.kaydet")}
        </Button>
    );

    const firmaSilButton = (kisininYetkileri.includes("ADMIN") || kisininYetkileri.includes("FIRMKS") || kisininYetkileri.includes("FIRM")) && (
        <Popconfirm
            key="deleteConfirm"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                firmaSil(id);
                navigate("/firmaList");
            }}
            style={{ width: 400 }}
        >
            <Button key="deleteButton" type="primary" danger size="small">
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error(t("genel.jpgPngOnly"));
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error(t("genel.lessThan10"));
        }
        return isJpgOrPng && isLt10M;
    };

    const handleUploadChange = (info) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            console.log("image upload done");
        }
    };

    const logoUpload = async ({ onError, onSuccess, file }) => {
        const parts = file.name.split(".");
        const fileExt = parts[parts.length - 1];
        const formData = new FormData();
        formData.append("logo", file, `logo.${fileExt}`);
        const createXHR = () => new XMLHttpRequest();
        fetch(`http://89.19.24.18:3001/Firma/${id}`, {
            body: formData,
            createXHR,
            method: "POST",
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setLogoURL(res.body);
            });
        setLoading(false);
    };

    useEffect(() => {
        setFormPasif(true);
        if (kisininYetkileri.includes("ADMIN")) setFormPasif(false);
        if (kisininYetkileri.includes("FIRMKY")) setFormPasif(false);
        if (kisininYetkileri.includes("FIRM")) setFormPasif(false);
    }, [kisininYetkileri]);

    useEffect(() => {
        if (firma) {
            form.setFieldsValue({
                firmaismi: firma.firmaismi,
                firmaUnvani: firma.firmaUnvani,
                ulke: firma.ulke,
                il: firma.il,
                ilce: firma.ilce,
                adres: firma.adres,
                postaKodu: firma.postaKodu,
                uavt: firma.uavt,
                telefonNo1: firma.telefonNo1,
                telefonNo2: firma.telefonNo2,
                vergiDairesi: firma.vergiDairesi,
                vergiNo: firma.vergiNo,
                tehlikeSinifi: firma.tehlikeSinifi ? t(`tehlikeSinifi.${firma.tehlikeSinifi}`) : null,
                sevesoSinifi: firma.sevesoSinifi,
                sgkNo: firma.sgkNo,
                mersisNo: firma.mersisNo,
                ticaretSicilNo: firma.ticaretSicilNo,
                kepAdresi: firma.kepAdresi,
                eTebligat: firma.eTebligat,
                naceKodu: firma.naceKodu,
                // naceKisim: firma.naceKisim ? t(`naceKisim.${firma.naceKisim}`) : null,
                faaliyetler: firma.faaliyetler === "" ? null : firma.faaliyetler,
                kmyBitisTarihi: firma.kmyBitisTarihi ? dayjs(firma.kmyBitisTarihi) : null,
                kmyMuhatap: firma.kmyMuhatap === "" ? null : firma.kmyMuhatap,
                uetdsBitisTarihi: firma.uetdsBitisTarihi ? dayjs(firma.uetdsBitisTarihi) : null,
                unetNo: firma.unetNo,
                uetdsPassword: firma.uetdsPassword,
                uetdsMuhatap: firma.uetdsMuhatap === "" ? null : firma.uetdsMuhatap,
                tmgdBitisTarihi: firma.tmgdBitisTarihi ? dayjs(firma.tmgdBitisTarihi) : null,
                tmgdkID: firma.tmgdkID,
                tmgdKapsam: firma.tmgdKapsam,
                tmgdMuhatap: firma.tmgdMuhatap === "" ? null : firma.tmgdMuhatap,
                firmaMuhatap: firma.firmaMuhatap === "" ? null : firma.firmaMuhatap,
                tmfbMuhatap: firma.tmfbMuhatap === "" ? null : firma.tmfbMuhatap,
                dgLogistics: firma.dgLogistics === "" ? null : firma.dgLogistics,
                uabYetkiBolgesi: firma.uabYetkiBolgesi === "" ? null : firma.uabYetkiBolgesi,
                asilTmgd: firma.asilTmgd,
                yedekTmgd: firma.yedekTmgd,
                ulkeililce: [firma.ulke, firma.il, firma.ilce],
            });
            setNaceKisim("");
            if (firma.sgkNo) {
                if (firma.sgkNo.length > 6) {
                    console.log("sgk : ", firma.sgkNo.substring(2, 6));
                    setNaceKisim(t(`nace4.${firma.sgkNo.substring(2, 6)}`));
                }
            }
            setUetdsCustomer(firma.uetdsMusterisiMi);
            setKmyCustomer(firma.kmyMusterisiMi);
            setTmgdk(firma.tmgdKurumuMu);
            // setNaceKisimKodu(firma.naceKisim);
            setTehlikeSinifiKodu(firma.tehlikeSinifi);
            setLogoURL(firma.logoURL);
            setLoading(false);
        }
    }, [firma]);

    const onSubmit = (e) => {
        setLoading(true);
        if (e.ulkeililce == null) {
            e.ulke = "";
            e.il = "";
            e.ilce = "";
            e.ulkeililce = "";
        } else {
            e.ulke = e.ulkeililce[0];
            e.il = e.ulkeililce[1];
            e.ilce = e.ulkeililce[2];
            e.ulkeililce = "";
        }
        e.firmaUnvani = e.firmaUnvani.toUpperCase();
        e.uetdsMusterisiMi = uetdsCustomer;
        e.kmyMusterisiMi = kmyCustomer;
        e.tmgdKurumuMu = tmgdk;
        // e.naceKisim = naceKisimKodu;
        e.tehlikeSinifi = tehlikeSinifiKodu;

        firmaGuncelle({ id, e });
        setLoading(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={formPasif}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    <KmyHeader caller="sayfaBaslik.firmaGuncelle" buttons={[firmaKaydetButton, firmaSilButton]} backURL="/firmaList" />
                    {contextHolder}
                    <Space direction="horizontal" style={{ width: "100%", margin: 20, justifyContent: "center" }}>
                        <ImgCrop
                            showGrid="true"
                            rotationSlider="true"
                            modalTitle="Resmi düzenle"
                            modalCancel={t("genel.vazgec")}
                            modalOk={t("genel.kaydet")}
                            minZoom={0.2}
                            aspect={3 / 1}
                            cropShape="rect"
                        >
                            <Upload
                                name="logo"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={logoUpload}
                                beforeUpload={beforeUpload}
                                onChange={handleUploadChange}
                            >
                                {logoURL ? (
                                    <img src={`${logoURL}`} alt="logo" style={{ width: 300, height: 100 }} />
                                ) : (
                                    <button style={{ border: 1, background: "none" }} type="button">
                                        {loading ? <LoadingOutlined style={{ cursor: "pointer" }} /> : <PlusOutlined style={{ cursor: "pointer" }} />}
                                        <div style={{ marginTop: 8, marginBottom: -20, cursor: "pointer" }}>Upload</div>
                                    </button>
                                )}
                            </Upload>
                        </ImgCrop>
                    </Space>
                    <Tabs
                        centered
                        tabPosition="top"
                        type="card"
                        defaultActiveKey="1"
                        items={[
                            {
                                key: "1",
                                label: t("firma.tabGenel"),
                                children: (
                                    <>
                                        <Form.Item
                                            name="firmaismi"
                                            label={t("firma.firmaismi")}
                                            rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Input placeholder={t("firma.firmaismiHint")} maxLength={255} />
                                        </Form.Item>
                                        <Form.Item
                                            name="firmaUnvani"
                                            label={t("firma.firmaUnvani")}
                                            rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                                        >
                                            <Input placeholder={t("firma.firmaUnvaniHint")} maxLength={255} style={{ textTransform: "uppercase" }} />
                                        </Form.Item>
                                        <Form.Item
                                            name="ulkeililce"
                                            label={t("firma.ulkeililce")}
                                            rules={[{ required: true, message: t("genel.gerekli") }]}
                                        >
                                            <Cascader options={ulkeililce} placeholder={t("genel.seciniz")} />
                                        </Form.Item>
                                        <Form.Item name="adres" label={t("firma.adres")}>
                                            <Input placeholder={t("firma.adresHint")} maxLength={255} />
                                        </Form.Item>
                                        <Form.Item name="postaKodu" label={t("firma.postaKodu")}>
                                            <Input placeholder={t("firma.postaKodu")} maxLength={10} />
                                        </Form.Item>
                                        <ConfigProvider locale={phoneLocale("trTR")}>
                                            <Form.Item name="telefonNo1" label={t("firma.telefonNo")}>
                                                <PhoneInput
                                                    defaultCountry={"TR"}
                                                    placeholder={t("firma.telefonNoHint")}
                                                    enableSearch={true}
                                                    disabled={formPasif}
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                        <ConfigProvider locale={phoneLocale("trTR")}>
                                            <Form.Item name="telefonNo2" label={t("firma.telefonNo")}>
                                                <PhoneInput
                                                    defaultCountry={"TR"}
                                                    placeholder={t("firma.telefonNoHint")}
                                                    enableSearch={true}
                                                    disabled={formPasif}
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                        <KisiSelect firmaID={id} name="firmaMuhatap" label={t("firma.firmaMuhatap")} />
                                        <KisiSelect firmaID={id} name="tmfbMuhatap" label={t("firma.tmfbMuhatap")} />
                                    </>
                                ),
                            },
                            {
                                key: "2",
                                label: t("firma.tabResmi"),
                                children: (
                                    <>
                                        <Form.Item name="vergiDairesi" label={t("firma.vergiDairesi")} style={{ marginTop: 20 }}>
                                            <Input
                                                placeholder={t("firma.vergiDairesiHint")}
                                                maxLength={50}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.toLocaleUpperCase("tr-TR");
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item name="vergiNo" label={t("firma.vergiNo")}>
                                            <Input placeholder={t("firma.vergiNoHint")} maxLength={15} />
                                        </Form.Item>
                                        <Form.Item name="sgkNo" label={t("firma.sgkNo")} rules={[{ whitespace: true }]}>
                                            <MaskedInput mask={"0.0000.00.00.0000000.000.00.00.000"} placeholder={t("firma.sgkNo")} maxLength={35} />
                                        </Form.Item>
                                        <Form.Item name="mersisNo" label={t("firma.mersisNo")} rules={[{ whitespace: true }]}>
                                            <Input placeholder={t("firma.mersisNo")} maxLength={20} />
                                        </Form.Item>
                                        <Form.Item name="ticaretSicilNo" label={t("firma.ticaretSicilNo")} rules={[{ whitespace: true }]}>
                                            <Input placeholder={t("firma.ticaretSicilNo")} maxLength={20} />
                                        </Form.Item>
                                        <Form.Item name="uavt" label={t("firma.uavt")}>
                                            <Input placeholder={t("firma.uavtHint")} maxLength={10} />
                                        </Form.Item>
                                        <Form.Item name="kepAdresi" label={t("firma.kepAdresi")}>
                                            <Input placeholder={t("firma.kepAdresiHint")} maxLength={60} />
                                        </Form.Item>
                                        <Form.Item name="eTebligat" label={t("firma.eTebligat")}>
                                            <Input placeholder={t("firma.eTebligat")} maxLength={60} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "3",
                                label: t("firma.tabFaaliyet"),
                                children: (
                                    <>
                                        <Form.Item name="naceKodu" label={t("firma.naceKodu")} style={{ width: "100%" }}>
                                            <Cascader
                                                options={naceKodlari}
                                                placeholder={t("genel.seciniz")}
                                                displayRender={(label, selectedOptions) => {
                                                    var tercume = `nace.${label}`;
                                                    return `${label} ${t(tercume)}`;
                                                }}
                                                onChange={(_, selectedOptions) => {
                                                    // setNaceKisimKodu(selectedOptions[2]["naceKisim"]);
                                                    setTehlikeSinifiKodu(selectedOptions[2]["tehlikeSinifi"]);
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label={t("firma.faaliyetKonusu")}>
                                            <Input placeholder={t("firma.naceKoduHint")} readOnly={true} value={naceKisim} />
                                        </Form.Item>

                                        <Form.Item name="tehlikeSinifi" label={t("firma.tehlikeSinifi")} style={{ marginTop: 20 }}>
                                            <Input placeholder={t("firma.naceKoduHint")} readOnly={true} />
                                        </Form.Item>
                                        <Form.Item name="sevesoSinifi" label={t("firma.sevesoSinifi")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option value="0">{t("genel.seciniz")}</Select.Option>
                                                <Select.Option value="1">{t("firma.sevesoSinifi1")}</Select.Option>
                                                <Select.Option value="2">{t("firma.sevesoSinifi2")}</Select.Option>
                                                <Select.Option value="3">{t("firma.sevesoSinifi3")}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="faaliyetler" label={t("firma.faaliyetler")}>
                                            <Select mode="tags" placeholder={t("genel.seciniz")}>
                                                <Select.Option key="1" value="1">
                                                    {t(`faaliyetler.Dolduran`)}
                                                </Select.Option>
                                                <Select.Option key="2" value="2">
                                                    {t(`faaliyetler.Paketleyen`)}
                                                </Select.Option>
                                                <Select.Option key="3" value="3">
                                                    {t(`faaliyetler.Yükleyen`)}
                                                </Select.Option>
                                                <Select.Option key="4" value="4">
                                                    {t(`faaliyetler.Gönderen`)}
                                                </Select.Option>
                                                <Select.Option key="5" value="5">
                                                    {t(`faaliyetler.Alıcı`)}
                                                </Select.Option>
                                                <Select.Option key="6" value="6">
                                                    {t(`faaliyetler.Boşaltan`)}
                                                </Select.Option>
                                                <Select.Option key="7" value="7">
                                                    {t(`faaliyetler.Taşımacı`)}
                                                </Select.Option>
                                                <Select.Option key="8" value="8">
                                                    {t(`faaliyetler.Tank Konteyner/Portatif Tank İşletmecisi`)}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Divider>
                                            <Checkbox
                                                name="tmgdKurumuMu"
                                                checked={tmgdk}
                                                onChange={(val) => {
                                                    setTmgdk(val.target.checked);
                                                }}
                                            >
                                                {t("firma.tmgdKurumuMu")}
                                            </Checkbox>
                                        </Divider>
                                        <Form.Item name="uabYetkiBolgesi" label={t("firma.uabYetkiBolgesi")}>
                                            <Select placeholder={t("genel.seciniz")} mode="multiple" disabled={!tmgdk}>
                                                <Select.Option value="1">I.Bölge (İstanbul)</Select.Option>
                                                <Select.Option value="2">II.Bölge (Ankara)</Select.Option>
                                                <Select.Option value="3">III.Bölge (İzmir)</Select.Option>
                                                <Select.Option value="4">IV.Bölge (Bursa)</Select.Option>
                                                <Select.Option value="9">IX.Bölge (Samsun)</Select.Option>
                                                <Select.Option value="5">V.Bölge (Adana)</Select.Option>
                                                <Select.Option value="6">VI.Bölge (Antalya)</Select.Option>
                                                <Select.Option value="7">VII.Bölge (Gaziantep)</Select.Option>
                                                <Select.Option value="8">VIII.Bölge (Diyarbakır)</Select.Option>
                                                <Select.Option value="10">X.Bölge (Erzurum)</Select.Option>
                                                <Select.Option value="11">XI.Bölge (Trabzon)</Select.Option>
                                                <Select.Option value="12">XII.Bölge (Sivas)</Select.Option>
                                                <Select.Option value="13">XIII.Bölge (Bolu)</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "4",
                                label: t("firma.tabBelgeler"),
                                children: <Belgeler sahipTipi="FIRM" id={id} />,
                            },
                            kisininYetkileri.includes("ADMIN")
                                ? {
                                      key: "5",
                                      label: t("firma.tabYazilim"),
                                      children: (
                                          <>
                                              <Divider>
                                                  <Checkbox
                                                      name="kmyMusterisiMi"
                                                      checked={kmyCustomer}
                                                      onChange={(val) => {
                                                          setKmyCustomer(val.target.checked);
                                                      }}
                                                  >
                                                      {t("firma.kmyMusterisiMi")}
                                                  </Checkbox>
                                              </Divider>
                                              <Form.Item name="kmyBitisTarihi" label={t("firma.bitisTarihi")}>
                                                  <DatePicker
                                                      style={{ width: "100%" }}
                                                      locale={locale}
                                                      format={"YYYY/MM/DD"}
                                                      placeholder={t("firma.bitisTarihiHint")}
                                                      disabled={!kmyCustomer}
                                                  />
                                              </Form.Item>
                                              <KisiSelect firmaID={id} name="kmyMuhatap" label={t("firma.kmyMuhatap")} />
                                              <Divider>
                                                  <Checkbox
                                                      name="uetdsMusterisiMi"
                                                      checked={uetdsCustomer}
                                                      onChange={(val) => {
                                                          setUetdsCustomer(val.target.checked);
                                                      }}
                                                  >
                                                      {t("firma.uetdsMusterisiMi")}
                                                  </Checkbox>
                                              </Divider>
                                              <Form.Item name="uetdsBitisTarihi" label={t("firma.bitisTarihi")}>
                                                  <DatePicker
                                                      style={{ width: "100%" }}
                                                      locale={locale}
                                                      format={"YYYY/MM/DD"}
                                                      placeholder={t("firma.bitisTarihiHint")}
                                                      disabled={!uetdsCustomer}
                                                  />
                                              </Form.Item>
                                              <Form.Item name="unetNo" label={t("firma.unetNo")}>
                                                  <Input placeholder={t("firma.unetNoHint")} maxLength={15} type="number" disabled={!uetdsCustomer} />
                                              </Form.Item>
                                              <Form.Item name="uetdsPassword" label={t("firma.uetdsPassword")}>
                                                  <Input.Password
                                                      placeholder={t("firma.uetdsPasswordHint")}
                                                      maxLength={15}
                                                      autoComplete="false"
                                                      disabled={!uetdsCustomer}
                                                  />
                                              </Form.Item>
                                              <KisiSelect firmaID={id} name="uetdsMuhatap" label={t("firma.uetdsMuhatap")} />
                                          </>
                                      ),
                                  }
                                : null,
                            {
                                key: "6",
                                label: t("firma.tabTmgd"),
                                children: (
                                    <>
                                        <FirmaSelect name="tmgdkID" label={t("firma.tmgdk")} tmgdk={true} />
                                        <Form.Item name="tmgdBitisTarihi" label={t("firma.bitisTarihi")}>
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                locale={locale}
                                                format={"YYYY/MM/DD"}
                                                placeholder={t("firma.bitisTarihiHint")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="tmgdKapsam" label={t("firma.tmgdKapsam")}>
                                            <Select mode="tags" placeholder={t("genel.seciniz")}>
                                                <Select.Option key="ADR" value="ADR">
                                                    ADR
                                                </Select.Option>
                                                <Select.Option key="RID" value="RID">
                                                    RID
                                                </Select.Option>
                                                <Select.Option key="IMDG" value="IMDG">
                                                    IMDG
                                                </Select.Option>
                                                <Select.Option key="DGR" value="DGR">
                                                    DGR
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <KisiSelect firmaID={form.getFieldValue("tmgdkID")} name="asilTmgd" label={t("firma.asilTmgd")} />
                                        <KisiSelect firmaID={form.getFieldValue("tmgdkID")} name="yedekTmgd" label={t("firma.yedekTmgd")} />
                                        <KisiSelect firmaID={form.getFieldValue("tmgdkID")} name="tmgdMuhatap" label={t("firma.tmgdMuhatap")} />
                                    </>
                                ),
                            },
                        ]}
                    />
                </Form>
            </Content>
        </Layout>
    );
}

export default FirmaGuncelle;
