import { React } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import { Avatar, Tooltip } from "antd";

function KisiResmi() {
    const navigate = useNavigate();
    const { adSoyad, profilePhoto, kisiID } = useGetUserInfo();

    return (
        <Tooltip title={adSoyad}>
            <Avatar
                key="kisiAvatar"
                src={profilePhoto && `${profilePhoto}`}
                alt={adSoyad}
                referrerPolicy="no-referrer"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    navigate(`/kisiFirma/${kisiID}`);
                }}
            ></Avatar>
        </Tooltip>
    );
}

export default KisiResmi;
