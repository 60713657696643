import { Form, Button, Input, notification, Select, Space } from "antd";
import { React, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetUserInfo } from "../hooks/useGetUserInfo";

function SahaDegerleri(props) {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const { firmaID } = useGetUserInfo();
    const [sahaDegerleri, setSahaDegerleri] = useState([]);
    const [sahaOptions, setSahaOptions] = useState([]);
    const accessToken = JSON.parse(localStorage.getItem("kmyToken"))["accessToken"] || {};

    const yetkiler = [99999]; //auth.permissions;
    const [sahaDegeri, setSahaDegeri] = useState("");

    const degerleriGetir = async () => {
        let unsubscribe;
        fetch(`http://89.19.24.18:3001/SahaDegerleri/saha/${props.id}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                unsubscribe = data;
                setSahaDegerleri(data);
            });
        setSahaDegerleri(unsubscribe);
    };

    useEffect(() => {
        if (sahaDegerleri) {
            const opts = sahaDegerleri.map((item) => ({ label: `${item.sahaDegeri}`, key: item.id }));
            setSahaOptions(opts);
        }
    }, [sahaDegerleri]);

    useEffect(() => {
        degerleriGetir();
    }, []);

    const sahaRef = useRef(null);

    return (
        <Form.Item name={props.name} label={props.label}>
            <Select
                key={props.id}
                mode={props.mode}
                placeholder={props.hint}
                disabled={props.disabled}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Space.Compact style={{ width: "100%" }}>
                            <Input
                                placeholder={t("genel.secenekEkle")}
                                ref={sahaRef}
                                value={sahaDegeri}
                                onChange={(e) => {
                                    setSahaDegeri(e.target.value);
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                                disabled={sahaDegeri == ""}
                                type="primary"
                                onClick={() => {
                                    fetch(`http://89.19.24.18:3001/SahaDegerleri/`, {
                                        method: "PUT",
                                        headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
                                        body: JSON.stringify({
                                            firmaID: firmaID,
                                            sahaID: props.id,
                                            sahaDegeri: sahaDegeri,
                                        }),
                                    })
                                        .then(function (response) {
                                            return response.json();
                                        })
                                        .then(function (data) {
                                            setSahaDegeri("");
                                            degerleriGetir();
                                        });
                                }}
                            >
                                {t("genel.ekle")}
                            </Button>
                        </Space.Compact>
                    </>
                )}
            >
                {sahaOptions.map((item, index) =>
                    sahaDegeri === null || item.label.toLowerCase().includes(sahaDegeri.toLowerCase()) ? (
                        <Select.Option value={item.label} key={index}>
                            {item.label}
                        </Select.Option>
                    ) : null
                )}
            </Select>
        </Form.Item>
    );
}

export default SahaDegerleri;
