import { useGetToken } from "./useGetToken";
import { useEffect, useState } from "react";

export const useAcilDurumPlaniKaydet = () => {
    const { accessToken } = useGetToken();
    const acilDurumPlaniKaydet = async (e) => {
        console.log("kaydedilecek : ", e);
        fetch(`http://89.19.24.18:3001/AcilDurumPlani/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                return data.id;
                console.log("ADP kayıt cevabı : ", data.id);
            });
    };

    return { acilDurumPlaniKaydet };
};

export const useAcilDurumPlaniSil = (id) => {
    const { accessToken } = useGetToken();
    const acilDurumPlaniSil = async () => {
        fetch(`http://89.19.24.18:3001/AcilDurumPlani/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { acilDurumPlaniSil };
};

export const useAcilDurumPlaniListele = () => {
    const { accessToken } = useGetToken();
    const [acilDurumPlaniListesi, setAcilDurumPlaniListesi] = useState([]);
    const acilDurumPlaniListele = async () => {
        fetch("http://89.19.24.18:3001/AcilDurumPlani", {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setAcilDurumPlaniListesi(data);
            });

        return () => acilDurumPlaniListesi;
    };
    useEffect(() => {
        acilDurumPlaniListele();
    }, []);

    return { acilDurumPlaniListesi };
};

export const useAcilDurumPlaniGetir = (id) => {
    const [acilDurumPlani, setAcilDurumPlani] = useState([]);
    const { accessToken } = useGetToken();
    const acilDurumPlaniGetir = async () => {
        fetch(`http://89.19.24.18:3001/AcilDurumPlani/${id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setAcilDurumPlani(data);
            });

        return () => acilDurumPlani;
    };

    useEffect(() => {
        acilDurumPlaniGetir();
    }, []);

    return { acilDurumPlani };
};
