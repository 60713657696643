import { Form, Input, Layout } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import { useYanginTupuEkle } from "../hooks/useYanginTupu";

const { Content } = Layout;

function YanginTupuEkle() {
    const { t } = useTranslation();
    const { yanginTupuEkle } = useYanginTupuEkle();

    const onSubmit = (e) => {
        yanginTupuEkle(e);
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="yanginTupuEkleForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    <Form.Item
                        name="konum"
                        label={t("yangintupu.konum")}
                        rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                    >
                        <Input placeholder={t("yangintupu.konumHint")} />
                    </Form.Item>
                    <Form.Item
                        name="aciklama"
                        label={t("yangintupu.aciklama")}
                        rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                    >
                        <Input placeholder={t("yangintupu.aciklama")} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default YanginTupuEkle;
